import { FC, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  Link,
  Alert,
  LinearProgress,
} from "@mui/material";
import * as yup from "yup";
import { CognitoUser } from "amazon-cognito-identity-js";
import useSnackBars from "../../hooks/useSnackbar";
import useCognito from "../../hooks/useCognito";

const validationSchema = yup.object({
  code: yup.string().required("Code is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
});

const ConfirmPassword: FC = () => {
  const navigate = useNavigate();
  const { userPool } = useCognito();
  let { email } = useParams();
  const { setAlerts } = useSnackBars();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ status: false, msg: "" });
  const formik = useFormik({
    initialValues: {
      code: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      setError({ status: false, msg: "" });

      const userData = {
        Username: email || "",
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.confirmPassword(values.code, values.password, {
        onSuccess() {
          setLoading(false);
          setAlerts([
            {
              severity: "success",
              msg: "Password successfully changed.",
            },
          ]);
          navigate("/login");
        },
        onFailure(err) {
          setLoading(false);
          setError({ status: true, msg: err.message || JSON.stringify(err) });
        },
      });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            variant="h3"
            fontSize={"22px"}
            fontWeight={"500"}
            color={"secondary"}
            sx={{ pt: 0, pb: 4 }}
          >
            Reset your password
          </Typography>
          <Typography
            variant="h5"
            fontSize={"16px"}
            fontWeight={"500"}
            color={"black"}
            sx={{ pt: 0, pb: 2 }}
          >
            Please check your email for an authentication code sent from secure@6pillars.ai
          </Typography>
          <Typography
            variant="h5"
            fontSize={"16px"}
            fontWeight={"500"}
            color={"black"}
            sx={{ pt: 0, pb: 4 }}
          >
            Enter the code and click confirm
          </Typography>
          <TextField
            fullWidth
            id="code"
            name="code"
            label="Code"
            value={formik.values.code}
            sx={{ maxWidth: "350px", pb: 2 }}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Create Password"
            type="password"
            sx={{ maxWidth: "350px", pb: 2 }}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            sx={{ maxWidth: "350px", pb: 2 }}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.password && Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Link
              onClick={() => {
                navigate("/login");
              }}
              sx={{
                display: "flex",
                mt: 2,
                mb: 2,
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <Typography variant="caption" fontSize={"16px"} color="secondary">
                Back to Login
              </Typography>
            </Link>
          </Box>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
            sx={{ mb: 2, width: "90px", backgroundColor: "secondary.main" }}
          >
            Reset
          </Button>
          {error.status && <Alert severity="error">{error.msg}</Alert>}
          <Divider />
          <Link
            sx={{ pt: 4, pb: 2, textDecoration: "none", cursor: "pointer" }}
            onClick={() => {
              navigate("/signup");
            }}
          >
            <Typography variant="caption" fontSize={"16px"} color="secondary">
              Don't have an account? Sign up for FREE here.
            </Typography>
          </Link>
          {loading && <LinearProgress color="secondary" />}
        </Box>
      </form>
    </div>
  );
};

export default ConfirmPassword;
