import React, { useState, useEffect } from "react";
import {
  IconButton,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  Container,
  Stack,
  TextField,
  InputAdornment,
  Grid,
  InputLabel,
  FormControl,
  MenuItem,
  Pagination,
  CircularProgress,
  TableCell,
  Link,
  SvgIcon,
  Checkbox,
} from "@mui/material";

import {
  SearchOutlined,
  RefreshOutlined,
  DeleteOutlined,
} from "@mui/icons-material";

import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import StyledTableCell from "../common/StyledTableCell";
import StyledSelect from "../common/StyledSelect";

import { useGetTemplateEngineSecurityResponseLazyQuery } from "../../generated";
import useGetScannnerResults from "../../hooks/useGetScannnerResults";
import useSnackBars from "../../hooks/useSnackbar";
import { LoadingButton } from "@mui/lab";

const NoSortSvg = (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8V16.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5L6 16.5L4 14.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V1.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.5L10 1.5L12 3.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

const AscSvg = (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8V16.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5L6 16.5L4 14.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V1.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.5L10 1.5L12 3.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

const descSvg = (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8V16.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5L6 16.5L4 14.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V1.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.5L10 1.5L12 3.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

interface ScanResult {
  filename?: string;
  alias?: string;
  recordCount?: Number;
  passed?: Number;
  failed?: Number;
  created_at?: string;
}

let hasFiltered: boolean = false;

const getResultsPageUrl = (summaryId: string) => {
  summaryId = encodeURIComponent(summaryId);
  return `/template-scanner-results?id=${summaryId}`;
};

const timezone = (date: any) => {
  const utcDate = date.includes("Z") ? date : date.replace(" ", "T") + "Z";
  const localtime = new Date(utcDate).toLocaleString();
  return localtime;
};

const ControlledCheckbox: React.FC<{
  summaryId: string;
  checked: boolean;
  onCheckboxChange: any;
}> = ({ summaryId, checked, onCheckboxChange }) => {
  return (
    <Checkbox
      checked={checked}
      onChange={() => onCheckboxChange(summaryId, !checked)}
      inputProps={{ "aria-label": "Delete Template Checkbox" }}
    />
  );
};

const ScannerResults = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [filteredData, setFilteredData] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [sortField, setSortField] = useState<keyof ScanResult | null>(
    "created_at"
  );
  const [selectedSummaryIds, setSelectedSummaryIds]: any[] = useState([]);
  const [uncheckAll, setUncheckAll] = useState(true);
  const [checkboxStates, setCheckboxStates]: any = useState({});
  const { summaryItems, loading, refetch } = useGetScannnerResults();
  const { setAlerts } = useSnackBars();

  useEffect(() => {
    if (summaryItems.length > 0) {
      setFilteredData(summaryItems);
    }
  }, [summaryItems, loading]);

  useEffect(() => {
    if (uncheckAll) {
      // Reset all checkboxes
      const newStates: any = summaryItems.reduce(
        (acc: any, item: any) => ({
          ...acc,
          [item.summaryId]: false,
        }),
        {}
      );
      setCheckboxStates(newStates);
      // Reset the uncheckAll flag
      setUncheckAll(false);
    }
  }, [uncheckAll]);

  const [DeleteTemplate] = useGetTemplateEngineSecurityResponseLazyQuery();

  const requestSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Set filtered flag if not already set
    if (!hasFiltered) hasFiltered = true;

    const { value: searchValue } = event.target;
    const search = searchValue.toLowerCase();

    const found = summaryItems.filter((item: ScanResult) => {
      const filename = item.filename?.toLowerCase() ?? "";
      const alias = item.alias?.toLowerCase() ?? "";
      const createdAt = item.created_at?.toLowerCase() ?? "";

      return (
        filename.includes(search) ||
        alias.includes(search) ||
        createdAt.includes(search)
      );
    });
    setFilteredData(found);
  };

  const getSortedData = () => {
    if (!sortField) return filteredData;

    const sorted = [...filteredData].sort((a: any, b: any) => {
      const aValue = a[sortField] || "";
      const bValue = b[sortField] || "";

      if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
      if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    return sorted;
  };

  const handleTableSort = (fieldName: keyof ScanResult | null) => {
    if (sortField === fieldName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(fieldName);
      setSortOrder("asc");
    }
  };

  const addLineBreaks = (string: string) => {
    if (typeof string === "string" && string) {
      const result = [];
      while (string.length) {
        result.push(string.substring(0, 25));
        string = string.substring(25);
      }
      return result;
    }
    return null;
  };

  const addTemplateToDelete = (summaryId: string) => {
    const newArray = [...selectedSummaryIds, summaryId];
    setSelectedSummaryIds(newArray);
  };

  const refresh = () => {
    refetch();
    setUncheckAll(true);
    setFilteredData([]);
    setSelectedSummaryIds([]);
    setUncheckAll(true);
    setPage(0);
  };

  const handleCheckboxChange = (summaryId: string, checked: boolean) => {
    addTemplateToDelete(summaryId);
    setCheckboxStates({ ...checkboxStates, [summaryId]: checked });
  };

  const handleDeleteOnClick = () => {
    if (selectedSummaryIds.length === 0) {
      alert("Please select a template to delete!");
      return;
    }
    setDeleteLoading(true);
    DeleteTemplate({
      variables: {
        processType: "deleteTemplate",
        summaryList: selectedSummaryIds.join(","),
      },
      context: {
        apiName: "template_scanner",
      },
      onCompleted: (data) => {
        setDeleteLoading(false);
        if (data.getTemplateEngineSecurityResponse?.msg === "success") {
          setAlerts([
            { severity: "success", msg: "Successfully deleted templates!" },
          ]);
          refresh();
        } else {
          setAlerts([
            {
              severity: "error",
              msg:
                "Error Deleting template. " +
                data.getTemplateEngineSecurityResponse?.error,
            },
          ]);
        }
      },
    });
  };

  const sortIcon = (sort: boolean) => {
    if (sort && sortOrder === "asc") {
      return AscSvg;
    }
    if (sort && sortOrder === "desc") {
      return descSvg;
    }
    return NoSortSvg;
  };

  return (
    <Container component="main">
      <Stack spacing={2}>
        <Paper>
          <Grid container flexDirection="row">
            <Paper
              component="form"
              elevation={0}
              sx={{
                p: "2px 4px",
                mb: 2,
                display: "flex",
                border: 0,
                boxShadow: 0,
                flexGrow: 1,
              }}
            >
              <TextField
                id="SearchTextField"
                label="Search"
                sx={{
                  width: "305px",
                  height: "40px",
                  marginLeft: "32px",
                  alignSelf: "center",
                }}
                onInput={requestSearch}
                onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchOutlined color="secondary" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
            <Paper
              component="form"
              elevation={0}
              sx={{
                p: "2px 4px",
                mb: 2,
                mt: "32px",
                display: "flex",
                justifyContent: "flex-end",
                flexGrow: 1,
              }}
            >
              <InputLabel
                id="page-size"
                sx={{
                  alignSelf: "center",
                  p: 1,
                  color: "#000000",
                  fw: "400",
                }}
              >
                Show
              </InputLabel>
              <FormControl
                sx={{ m: 1, marginRight: "32px", minWidth: 119 }}
                size="small"
              >
                <StyledSelect
                  labelId="page-size-select-label"
                  id="page-size-select"
                  defaultValue={20}
                  onChange={(event: any) => {
                    setRowsPerPage(event.target.value);
                  }}
                >
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </StyledSelect>
              </FormControl>
              <LoadingButton
                id="refresh-button"
                variant="outlined"
                sx={{ alignSelf: "center", p: 1, marginRight: "32px" }}
                disabled={loading}
                loading={loading}
                onClick={refresh}
                endIcon={<RefreshOutlined />}
              >
                Refresh
              </LoadingButton>
            </Paper>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ pr: 4 }}
          >
            <Grid item>
              <LoadingButton
                startIcon={<DeleteOutlined />}
                disabled={deleteLoading}
                loading={deleteLoading}
                variant="contained"
                color="secondary"
                onClick={() => handleDeleteOnClick()}
              >
                Delete Results
              </LoadingButton>
            </Grid>
          </Grid>
          <TableContainer sx={{ padding: "32px" }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Delete</StyledTableCell>
                  <StyledTableCell onClick={() => handleTableSort("filename")}>
                    File Name {sortIcon(sortField === "filename")}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleTableSort("alias")}>
                    Alias Name {sortIcon(sortField === "alias")}
                  </StyledTableCell>
                  {/* <StyledTableCell
                    onClick={() => handleTableSort("recordCount")}
                  >
                    Checks {sortIcon(sortField === "recordCount")}
                  </StyledTableCell> */}
                  <StyledTableCell
                    onClick={() => handleTableSort("passed")}
                    sx={{ minWidth: "100px", p: 0 }}
                  >
                    Passed {sortIcon(sortField === "passed")}
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => handleTableSort("failed")}
                    sx={{ minWidth: "100px", p: 0 }}
                  >
                    Failed {sortIcon(sortField === "failed")}
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => handleTableSort("created_at")}
                    sx={{ minWidth: "100px", p: 0 }}
                  >
                    Timestamp {sortIcon(sortField === "created_at")}
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && summaryItems.length === 0 && (
                  <TableCell align="center" colSpan={7}>
                    <CircularProgress color="secondary" />
                  </TableCell>
                )}
                {!loading && summaryItems.length === 0 && (
                  <TableRow>
                    <StyledTableCell colSpan={7} align={"center"}>
                      No Results
                    </StyledTableCell>
                  </TableRow>
                )}
                {summaryItems.length > 0 &&
                  getSortedData()
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((summaryItem: any, index: number) => {
                      let processedFilename = "";
                      if (summaryItem.filename) {
                        if (summaryItem.filename.length) {
                          const lineBreaks = addLineBreaks(
                            summaryItem.filename
                          );
                          processedFilename = lineBreaks
                            ? lineBreaks.map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))
                            : summaryItem.filename;
                        }
                      }
                      return (
                        <>
                          <TableRow key={index}>
                            <StyledTableCell>
                              <ControlledCheckbox
                                summaryId={summaryItem.summaryId}
                                checked={
                                  checkboxStates[summaryItem.summaryId] || false
                                }
                                onCheckboxChange={handleCheckboxChange}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              {processedFilename}
                            </StyledTableCell>
                            <StyledTableCell>
                              {summaryItem.alias}
                            </StyledTableCell>
                            {/* <StyledTableCell>
                              {summaryItem.recordCount}
                            </StyledTableCell> */}
                            <StyledTableCell>
                              {summaryItem.passed}
                            </StyledTableCell>
                            <StyledTableCell>
                              {summaryItem.failed}
                            </StyledTableCell>
                            <StyledTableCell>
                              {timezone(summaryItem.created_at)}
                            </StyledTableCell>
                            <StyledTableCell>
                              {summaryItem.failed + summaryItem.passed > 0 ? (
                                <Link
                                  href={getResultsPageUrl(
                                    summaryItem.summaryId
                                  )}
                                  underline="none"
                                >
                                  <IconButton
                                    aria-label="View Results"
                                    size="small"
                                    color="secondary"
                                  >
                                    <VisibilityRoundedIcon></VisibilityRoundedIcon>
                                  </IconButton>
                                </Link>
                              ) : (
                                <IconButton
                                  aria-label="View Results"
                                  size="small"
                                  color="secondary"
                                  disabled={true}
                                >
                                  <VisibilityRoundedIcon></VisibilityRoundedIcon>
                                </IconButton>
                              )}
                            </StyledTableCell>
                          </TableRow>
                        </>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: "10px",
              color: "primary.main",
              borderColor: "primary.main",
            }}
            shape="rounded"
            count={Math.ceil(getSortedData().length / rowsPerPage)}
            page={page + 1}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
              setPage(value - 1);
            }}
          />
        </Paper>
      </Stack>
    </Container>
  );
};
export default ScannerResults;
