import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  LinearProgress,
  Grid,
  Typography,
  Alert,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Control from "../../models/Control";
import { Row } from "@tanstack/react-table";
import { useUpdateControlRemediateStatus } from "../../hooks/useUpdateControlRemediateStatus";
import useAccount from "../../hooks/useAccount";
import SixPillarsRoleButton from "./SixPillarsRoleButton";
import useNewrelic from "../../hooks/useNewrelic";

const AutomatedRemediationDialog: FC<any> = ({
  open,
  handleClose,
  handleConfirm,
  row,
}: {
  open: boolean;
  handleClose: any;
  handleConfirm: any;
  row: Row<Control>;
}) => {
  const { arn, accounttype, defaultregion, externalId } = useAccount();
  const { noticeError } = useNewrelic();
  const [error, setError] = useState({ status: false, msg: "" });
  let action =
    row.original.remediatestatus === "ENABLED" ? "Disable" : "Enable";

  const {
    updateControlAutoRemediateStatus,
    loading,
    status,
    controlsStatus,
    reset,
  } = useUpdateControlRemediateStatus();

  useEffect(() => {
    if (
      controlsStatus.length > 0 &&
      !controlsStatus.some(
        (controlResult: any) => controlResult?.msg === "ROLE_NOT_FOUND"
      )
    ) {
      handleConfirm(status, controlsStatus, "");
      reset();
    }
  }, [loading]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {action} Continuous Compliance
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" fontSize={"14px"}>
            {`Control ID: ${
              row.original.controlname
            } You are about to ${action.toLocaleLowerCase()}
            continuous compliance for this control`}
          </DialogContentText>
          {(() => {
            const remediationSteps = row.original.remediationsteps;

            if (!remediationSteps) {
              return null;
            }
            let parsedData;
            try {
              parsedData = JSON.parse(remediationSteps);
            } catch (error) {
              noticeError(error, "AutomatedRemediationDialog");
            }
            if (!parsedData) {
              return null;
            }
            const hasDescription =
              parsedData.description &&
              typeof parsedData.description === "string" &&
              parsedData.description.trim() !== "";
            const hasDefaultValues =
              parsedData.defaultValues &&
              typeof parsedData.defaultValues === "object" &&
              Object.keys(parsedData.defaultValues).length > 0;

            if (!hasDescription && !hasDefaultValues) {
              return null;
            }

            return (
              <>
                {hasDescription && (
                  <Grid container sx={{ m: 1 }}>
                    <Grid item md={3}>
                      <Typography fontSize={"14px"}>
                        Remediation Description:
                      </Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography
                        fontSize={"14px"}
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {parsedData.description}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {hasDefaultValues && (
                  <Grid container sx={{ m: 1 }}>
                    <Grid item md={3}>
                      <Typography fontSize={"14px"}>
                        Remediation Default Values:
                      </Typography>
                    </Grid>
                    <Grid item md={9}>
                      <Typography
                        fontSize={"14px"}
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {Object.entries(parsedData.defaultValues).map(
                          ([key, value]) => {
                            return (
                              <React.Fragment>
                                <b>{`${key}`}</b>: {`${value}`}
                                <br />
                              </React.Fragment>
                            );
                          }
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </>
            );
          })()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={() => {
              let val = action.toUpperCase();
              updateControlAutoRemediateStatus(
                arn,
                [row.original.controlname],
                val
              );
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
        {loading && <LinearProgress color="secondary" />}
        {controlsStatus.map((controlResult: any, index) => {
          if (!controlResult?.status) {
            return controlResult?.msg === "ROLE_NOT_FOUND" ? (
              <>
                <Alert severity="error">
                  <Typography sx={{ fontSize: "12px" }}>
                    We couldn't find a suitable role with the necessary
                    permissions to enable continuous compliance of this service.
                    To update your existing role, please follow these
                    instructions.
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 1: Click the "Open CloudFormation" button below to open
                    CloudFormation in your AWS account
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 2: Select the "six-pillars" stack
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 3: Click "Update"
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 4: Select "Replace Existing Template"
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 5: In AUTOMATE+, click on the "Copy Role Stack URL"
                    button
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 6: In the AWS Console, paste the Role Stack URL into
                    the "Amazon S3 URL" field and click "Next" until you can
                    select "Submit"
                  </Typography>
                  <SixPillarsRoleButton
                    launch={false}
                    update={true}
                    open={true}
                    uuid={externalId}
                    accountRegion={defaultregion}
                    template={
                      accounttype === "AUTOMATED"
                        ? "roles/SixPillarRoleStack.template.json"
                        : "roles/SixPillarReadOnlyRoleStack.template.json"
                    }
                  ></SixPillarsRoleButton>
                </Alert>
              </>
            ) : (
              <Alert severity="error">{error.msg}</Alert>
            );
          }
        })}
      </Dialog>
    </>
  );
};

export default AutomatedRemediationDialog;
