import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import { useGetUrlQuery } from "../../../generated";
import { v5 as uuidv5 } from "uuid";
import { OpenInNew } from "@mui/icons-material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DownloadTemplateModal = () => {
  const [open, setOpen] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [uuid, setUuid] = useState("");
  const [accountRegion, setAccountRegion] = useState("");
  //Get CFN Template for six pillars stack and open it in new tab window
  const { data, loading } = useGetUrlQuery({
    variables: {
      template: "roles/SixPillarReadOnlyRoleManualStack.template.json", // value for 'template'
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, "0");
  };

  const getUuid = (accountId: string) => {
    let dateTime = new Date();
    const timeStr = [
      padTo2Digits(dateTime.getHours()),
      padTo2Digits(dateTime.getMinutes()),
      dateTime.getSeconds().toString(),
    ].join("");
    return uuidv5(
      `${timeStr}-${accountId}`,
      process.env.REACT_APP_UUID_NAMESPACE || ""
    );
  };

  useEffect(() => {
    setAccountId("");
    setUuid("");
  }, [open]);

  return (
    <>
      <Button variant="outlined" onClick={handleClick} sx={{ m: 1 }}>
        Prerequisites
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box>Instructions for Manual Deployment</Box>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={12} sx={{ pt: 1 }}>
              <Typography>
                1. Enable Security Hub in the AWS Account If Security Hub is not
                already enabled, ensure it is activated, and verify that the
                Consolidated Controls option is turned on.
              </Typography>
              <Alert severity="info" sx={{ pt: 1 }}>
                If you have an organizational setup in AWS, you can centrally
                enable Security Hub using your management account along with a
                delegated AWS account.
              </Alert>
            </Grid>
            <Grid item md={12} sx={{ pt: 1 }}>
              2. Create a Security Hub custom action using the following AWS
              CLI/ Cloud Shell.
              <Typography
                sx={{
                  backgroundColor: "grey.100",
                  color: "text.primary",
                  padding: "8px",
                  borderRadius: "4px",
                  fontSize: "0.8rem",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    'aws securityhub create-action-target --name Send_To_6pillars --description "Send Messages to 6pillars" --id SendTo6pillars'
                  );
                }}
              >
                <code>
                  aws securityhub create-action-target --name Send_To_6pillars
                  --description "Send Messages to 6pillars" --id SendTo6pillars
                </code>
              </Typography>
            </Grid>
            <Grid item md={12} sx={{ pt: 1 }}>
              3. Make sure that AWS Config is enabled in the account. (If you
              are using a central configuration and Security Hub is already
              enabled, skip this step.)
            </Grid>
            <Grid item md={12} sx={{ pt: 1 }}>
              4. Please enter the AWS account ID to generate a UUID.
              <TextField
                required
                fullWidth
                size="small"
                id="awsAccountId"
                placeholder="Deploy AWS Account ID"
                label="AWS Account ID"
                sx={{ mt: 2 }}
                onChange={(e) => {
                  setAccountId(e.target.value);
                  setUuid(getUuid(e.target.value));
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <TextField
                  required
                  fullWidth
                  size="small"
                  id="externalId"
                  label="External ID"
                  disabled
                  value={uuid}
                />
                <IconButton
                  disabled={accountId === ""}
                  onClick={() =>
                    navigator.clipboard.writeText(getUuid(accountId))
                  }
                >
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: 2,
              }}
            >
              5. Download the template and deploy the stack in the AWS account.
            </Grid>
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <LoadingButton
                id="automate-read-only-manual-template-download"
                rel={"noopener"}
                variant="contained"
                disabled={loading}
                onClick={async () => {
                  let url = data?.getTemplateS3URL;
                  console.log(url);
                  const element = document.createElement("a");
                  element.href = url;
                  element.download = "six-pillar-read-only-stack.json";
                  element.target = "_blank";
                  document.body.appendChild(element);
                  element.click();
                  document.body.removeChild(element);
                }}
                startIcon={<OpenInNew />}
                sx={{
                  display: "flex",
                  m: 1,
                  alignItems: "center",
                  bgcolor: "secondary.main",
                  "&:hover": {
                    bgcolor: "secondary.main",
                    color: "secondary.contrastText",
                  },
                }}
              >
                Open Template in S3
              </LoadingButton>
              <LoadingButton
                id="automate-read-only-manual-template-download"
                rel={"noopener"}
                variant="contained"
                disabled={loading || accountId === ""}
                onClick={async () => {
                  let url = data?.getTemplateS3URL;
                  let launchurl = `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?stackName=six-pillars&templateURL=${encodeURIComponent(
                    url
                  )}&param_ExternalId=${uuid}`;
                  navigator.clipboard.writeText(launchurl);
                }}
                startIcon={<ContentCopyIcon />}
                sx={{
                  display: "flex",
                  m: 1,
                  alignItems: "center",
                  bgcolor: "secondary.main",
                  "&:hover": {
                    bgcolor: "secondary.main",
                    color: "secondary.contrastText",
                  },
                }}
              >
                Quick Launch in CFN
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DownloadTemplateModal;
