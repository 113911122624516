import { Container, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Pagination, Paper, SelectChangeEvent, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useListMultiFrameworkQuery } from "../../../generated";
import { useState, useEffect, useCallback } from "react";
import StyledSelect from "../../common/StyledSelect";
import StyledTableCell from "../../common/StyledTableCell";
import ListMultiFrameworkItem from "./ListMultiFrameworkItem";

interface ListMultiFrameworksProps {
	arn: string;
	updateFramework: (
		frameworkName: string,
		standard: string,
		awsTechnicalReviewAccount: string,
		connectedAccount: string,
	) => void;
  resetStates: () => void;
	setUnlockSteps: React.Dispatch<
    React.SetStateAction<{
      step2: boolean;
      step3: boolean;
      step4: boolean;
      step5: boolean;
    }>
  >;
}

/**
 * List all frameworks for the user
 * @param arn arn of user
 * @param updateFramework calls updateFramework from Multiframework page
 * @param resetStates reset all the states when you land on this page
 * @param setUnlockSteps which step to unlock and lock in the tabs
 * @returns all the frameworks of the user available from the db
 */
export default function ListMultiFrameworks({
	arn,
	updateFramework,
  resetStates,
	setUnlockSteps,
}: ListMultiFrameworksProps){
	const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

	const { data, loading, error, refetch } = useListMultiFrameworkQuery({
    variables: {
  		arn,// value for 'arn'
    },
		context: {
      apiName: "well_architected",
    },
  });

  //Set which tabs need to be disabled
	const handleUnlockSteps = useCallback(() => {
    setUnlockSteps((prevState) => ({
      ...prevState,
      step2: false,
      step3: false,
      step4: false,
      step5: false, // Lock step 5
    }));
  }, [setUnlockSteps]);

	useEffect(() => {
    refetch({ arn });
    handleUnlockSteps();
    resetStates(); //reset useStates whenever list frameworks tab is clicked
  }, [data, refetch]);

	if (loading) {
    return (
      <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
        <LinearProgress color="secondary" />
      </Stack>
    );
  }

  if (error) {
    return (
      <Typography
        component={"span"}
        variant="h6"
        color="text.primary"
        paragraph
      >
        error...
      </Typography>
    );
  }

	return (
		<Container maxWidth="lg" component={Paper}>
      <Grid container sx={{ mb: 2 }} spacing={2} alignItems="center">
        <Grid item md={12}>
          <Paper
            component="form"
            elevation={0}
            sx={{
              p: "2px 4px",
              mb: 2,
              display: "flex",
              justifyContent: "flex-end",
              flexGrow: 1,
            }}
          >
            <InputLabel id="page-size" sx={{ alignSelf: "center", p: 1 }}>
              Show
            </InputLabel>
            <FormControl sx={{ m: 1, minWidth: 119 }} size="small">
              <StyledSelect
                labelId="page-size-select-label"
                id="page-size-select"
                defaultValue={rowsPerPage}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  setRowsPerPage(Number(e.target.value));
                  setPage(0);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </StyledSelect>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Compliance Workflow Name</StyledTableCell>
              <StyledTableCell>Created At</StyledTableCell>
              <StyledTableCell>Standard</StyledTableCell>
              <StyledTableCell>AWS Technical Review Account</StyledTableCell>
              <StyledTableCell>Connected Account</StyledTableCell>
              <StyledTableCell>Update</StyledTableCell>
              <StyledTableCell>Delete</StyledTableCell>
              <StyledTableCell sx={{ width: "5%" }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.listMultiFramework?.items
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((framework) => {
                return (
									<ListMultiFrameworkItem
										key={framework.frameworkName}
                    arn={arn}
										row={framework}
										updateFramework={updateFramework}
									/>
                );
						})}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        flexDirection="row"
        justifyContent={"flex-end"}
        sx={{ p: 0, pt: 2, pb: 2 }}
      >
        <Pagination
          count={
            data?.listMultiFramework?.items.length
              ? Math.ceil(data?.listMultiFramework?.items.length / rowsPerPage)
              : 0
          }
          variant="outlined"
          sx={{
            color: "primary.main",
            borderColor: "primary.main",
          }}
          shape="rounded"
          page={page + 1}
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            setPage(value - 1);
          }}
        />
      </Grid>
    </Container>
	);
}