import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Loader from "../../common/Loader";
import ConnectedAccount from "../../../models/ConnectedAccount";
import { useGetControlQuery } from "../../../generated";
import ComplianceStatus from "../../common/ComplianceStatus";
import { useState } from "react";

interface TechnicalControlProps {
  index: number;
  technicalReviewArn: string;
  row: any;
  connectedAccounts: Array<ConnectedAccount>;
}

/**
 * Display Technical Control information
 * @param index index value
 * @param technicalReviewArn technical reveiew arn that was selected when creating the framework
 * @param row row information
 * @param connectedAccounts state of connectedAccounts information
 * @returns row information of technical control
 */
export default function TechnicalControl({
  index,
  technicalReviewArn,
  row,
  connectedAccounts,
}: TechnicalControlProps) {
  //Query to get the sec hub findings
  const { data, loading } = useGetControlQuery({
    variables: {
      arn: technicalReviewArn,
      controlname: row.controlname,
      connectedAccounts,
    },
  });

  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        key={row.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {index + 1}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.controlname}
        </TableCell>
        <TableCell component="th" scope="row" width={"75%"}>
          {row.original.controldescription}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          <ComplianceStatus value={row.original.compliancestatus} />
        </TableCell>
        <TableCell width={"5%"}>
          <IconButton color="secondary" onClick={() => setOpen(!open)}>
            {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {loading ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Loader />
            </Collapse>
          </TableCell>
        </TableRow>
      ) : (
        data?.getControl?.findings?.map((finding: any) => {
          return (
            <TableRow
              key={finding?.Id?.split("/").reverse()[0]}
              sx={{ "& > *": { borderBottom: "unset" } }}
            >
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={2}
              >
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      borderBottom: "set",
                    }}
                  >
                    <ComplianceStatus value={finding.Compliance.Status} />
                  </Box>
                </Collapse>
              </TableCell>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={10}
              >
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box
                    sx={{ margin: 1, display: "block", borderBottom: "set" }}
                  >
                    {finding?.Resources &&
                      finding?.Resources[0]?.Name !== "N/A" && (
                        <Typography variant="body1" align="left" gutterBottom>
                          Name:{" "}
                          {finding?.Resources
                            ? finding?.Resources[0]?.Name
                            : ""}
                        </Typography>
                      )}
                    <Typography variant="body1" align="left" gutterBottom>
                      Service ID:{" "}
                      {finding?.Resources ? finding?.Resources[0]?.Id : ""}
                    </Typography>
                    <Typography variant="body1" align="left" gutterBottom>
                      Finding ID: {finding?.Id?.split("/").reverse()[0]}
                    </Typography>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          );
        })
      )}
    </>
  );
}
