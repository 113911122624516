import { useState, useEffect } from "react";
import {
  SecurityControl,
  useGetControlsQuery,
  useGetControlsStatusQuery,
  useGetSupportControlQuery,
} from "../generated";
import Control from "../models/Control";
import ConnectedAccount from "../models/ConnectedAccount";
import useNewrelic from "./useNewrelic";
interface ListSecurityControlsQueryInput {
  arn: string;
  connectedAccounts: Array<ConnectedAccount>;
}

const useListSecurityControlsQuery = ({
  arn,
  connectedAccounts,
}: ListSecurityControlsQueryInput) => {
  const [controls, setControls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { data: controlsStatus, refetch: refetchControlsStatus } =
    useGetControlsStatusQuery({
      variables: {
        arn,
        connectedAccounts,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    });

  const { data: sup1Control, refetch: refetchSupportControl } =
    useGetSupportControlQuery({
      variables: {
        arn: arn,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    });

  const { controls: awsControls } = useAwsControlsSubscription({
    arn,
    connectedAccounts,
  });

  const refetch = () => {
    setControls([]);
    setLoading(true);
    refetchSupportControl();
    //refetchAwsControls();
    refetchControlsStatus();
  };

  const transformControls = (control: Control) => {
    if (control?.controlname === sup1Control?.getSupportControl?.controlname) {
      return {
        ...control,
        compliancestatus: sup1Control?.getSupportControl.compliancestatus,
      };
    }
    const controlStatus = controlsStatus?.getControlsStatus?.items.find(
      (controlStatus: SecurityControl) =>
        controlStatus.controlname === control.controlname
    );
    if (controlStatus) {
      return {
        ...control,
        compliancestatus: controlStatus.compliancestatus,
        remediatestatus: controlStatus.remediatestatus,
        controlshlink: controlStatus.controlshlink,
        alertstatus: controlStatus.alertstatus,
        remediationsteps: controlStatus.remediationsteps,
        region: controlStatus.region,
      };
    }
    return control;
  };
  const updateData = (
    controlnames: string[],
    columnId: string,
    value: string
  ) => {
    refetchControlsStatus();
  };

  useEffect(() => {
    if (sup1Control?.getSupportControl && awsControls.length > 0) {
      let data: any = awsControls.map(transformControls);
      setControls(data);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [sup1Control, awsControls, controlsStatus]);

  useEffect(() => {
    if (
      controls.length === 0 &&
      sup1Control?.getSupportControl &&
      awsControls.length > 0
    ) {
      let data: any = awsControls.map(transformControls);

      setControls(data);
      setLoading(false);
    }
  }, [controls, controlsStatus, loading]);

  return { controls, loading, error, refetch, updateData };
};

const useAwsControlsSubscription = ({ arn, connectedAccounts }: any) => {
  const { noticeError } = useNewrelic();
  const [controls, setControls] = useState([]);
  const { data, loading, refetch, error }: any = useGetControlsQuery({
    variables: {
      arn: arn,
      connectedAccounts: connectedAccounts,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (Array.isArray(data?.getControls?.items)) {
      setControls(data?.getControls?.items);
    }
  }, [data]);

  useEffect(() => {
    if (controls.length === 0 && !loading) refetch();
  }, [controls]);

  useEffect(() => {
    if (error) {
      noticeError(error, "useListSecurityControlsQuery");
    }
  }, [error])
  
  return { controls, refetch };
};

export { useListSecurityControlsQuery };
