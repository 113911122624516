import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CreateWorkload from "./CreateWorkload";
import ListWorkloads from "./ListWorkloads";
import { client } from "../../../lib/clients/apolloClient";
import useAccount from "../../../hooks/useAccount";
import Heading from "../../common/Heading";
import AnswerQuestionnaire from "./AnswerQuestionnaire";
import AnswerBestPractice from "./AnswerBestPractice";
import Sow from "../common/SowRedirect";
import { Link, useNavigate } from "react-router-dom";
import { useGetCognitoAccountQuery } from "../../../generated";
import ConnectedAccount from "../../../models/ConnectedAccount";
import { Button, Grid, Stack } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface WorkloadsDictionary {
  [key: string]: string;
}

interface RiskCountDictionary {
  [key: string]: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </Container>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Wat() {
  const navigate = useNavigate();
  const { arn, defaultregion, accounts } = useAccount();
  const [value, setValue] = useState(0);
  const [createdWorkloads, setCreatedWorkloads] = useState<WorkloadsDictionary>(
    {}
  );
  const [riskCounts, setRiskCounts] = useState({
    hriCount: 0,
    initialHriCount: 0,
  });
  const [halfStageHriCount, setHalfStageHriCount] = useState<number>(0);
  const [firstRiskCountsDict, setFirstRiskCountsDict] =
    useState<RiskCountDictionary>({});
  const [riskCountsDict, setRiskCountsDict] = useState<RiskCountDictionary>({});
  const [stateStage, setStateStage] = useState<WorkloadsDictionary>({});
  const [unlockStep2, setUnlockStep2] = useState(false);
  const [unlockStep3, setUnlockStep3] = useState(false);
  const [process, setProcess] = useState("");
  const [technicalReviewArn, setTechnicalReviewArn] = useState("");
  const [connectedAccounts, setConnectedAccounts] = useState<
    Array<ConnectedAccount>
  >([]);
  const { data: getUserdata } = useGetCognitoAccountQuery({
    variables: {},
    notifyOnNetworkStatusChange: true,
  });

  const handleChange = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setValue(newValue);
    //update the queries by refetching it from the server again when you switch tabs
    client.refetchQueries({
      include: "active",
    });
  };

  const updateWorkLoad = async (
    workLoadId: string,
    workLoadName: string,
    highRiskCount: number,
    initialHighRiskCount: number,
    technicalReviewArn: string,
    connectedAccounts: Array<ConnectedAccount>
  ) => {
    setCreatedWorkloads({ [workLoadName]: workLoadId });
    setRiskCountsDict({ [workLoadId]: highRiskCount });
    setFirstRiskCountsDict({ [workLoadId]: initialHighRiskCount });
    setTechnicalReviewArn(technicalReviewArn);
    setProcess("UPDATE");
    setConnectedAccounts(connectedAccounts);

    const account = accounts.find((i: any) => i.arn === technicalReviewArn);
    //If connected Account is part of member account or if the targetAccount and connected account
    //are the same then set true to connectedAutomatePlus
    let connectedAutomatePlus = true;
    if (account) {
      let connectedAccountNumber = account?.connectedAccounts;
      if (connectedAccountNumber?.length > 1) {
        if (
          account?.member_accounts.includes(
            connectedAccounts?.[0]?.accountIdRef
          ) ||
          technicalReviewArn.split(":")[4] ===
            connectedAccounts?.[0]?.accountIdRef
        ) {
          connectedAutomatePlus = true;
        } else {
          connectedAutomatePlus = false;
        }
      }
    }

    //If AccountType is Automate+ and passes the connectedAutomatePlus check move to step 2
    //otherwise skip to step 3
    if (
      account?.accounttype === "AUTOMATED" &&
      connectedAutomatePlus === true
    ) {
      setUnlockStep2(true);
      setValue(1);
    } else {
      setUnlockStep3(true);
      setValue(2);
    }
  };

  //Set Risk Counts for the HRI Counter that is displayed
  useEffect(() => {
    const initialsum = Object.values<number>(firstRiskCountsDict).reduce(
      (acc, value) => acc + value,
      0
    );
    const risksum = Object.values<number>(riskCountsDict).reduce(
      (acc, value) => acc + value,
      0
    );
    setRiskCounts({
      hriCount: risksum,
      initialHriCount: initialsum,
    });
    setHalfStageHriCount(initialsum);
  }, [riskCountsDict, firstRiskCountsDict]); // Add Dicts as a dependencyy

  return (
    <Container component="main">
      <Container maxWidth="lg">
        <Heading heading={"AWS Well-Architected Framework Review"} />
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h4" color="primary.main" paragraph>
              Cloud Infrastructure
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                onClick={() => {
                  navigate("/watiac");
                }}
                type="submit"
                variant="outlined"
                sx={{
                  width: 250,
                  height: 40,
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                Switch to IaC
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="fullWidth"
          >
            <Tab label="STEP 1: Define Workload" {...a11yProps(0)} />
            <Tab
              label="STEP 2: Best-Practice Recommendations"
              {...a11yProps(1)}
              disabled={unlockStep2 === false}
            />
            <Tab
              label="STEP 3: People, Policy & Tools Review"
              {...a11yProps(2)}
              disabled={unlockStep3 === false}
            />
            <Tab
              label="Review Cloud Infrastructure Workloads"
              {...a11yProps(3)}
            />
            <Tab
              label="Manual Remediation SoW"
              component={Link}
              to="/rules?complianceStandard=AWS Well Architected Framework&compliant=FAILED"
              target="_blank"
              rel="noopener noreferrer"
              {...a11yProps(4)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} key={0}>
          <CreateWorkload
            arn={arn}
            region={defaultregion}
            organisation={getUserdata?.getCognitoAccount?.organisation}
            setCreatedWorkloads={setCreatedWorkloads}
            setRiskCountsDict={setRiskCountsDict}
            setFirstRiskCountsDict={setFirstRiskCountsDict}
            changeTab={(
              index: number,
              targetArn: string,
              connectedAccounts: Array<ConnectedAccount>
            ) => {
              setValue(index);
              setStateStage({});
              setProcess("START");
              setTechnicalReviewArn(targetArn);
              setConnectedAccounts(connectedAccounts);
            }}
            setUnlockStep2={setUnlockStep2}
            setUnlockStep3={setUnlockStep3}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AnswerBestPractice
            arn={arn}
            createdWorkloads={createdWorkloads}
            riskCounts={riskCounts}
            changeTab={(index: number) => {
              setValue(index);
            }}
            setUnlockStep3={setUnlockStep3}
            process={process}
            technicalReviewArn={technicalReviewArn}
            connectedAccounts={connectedAccounts}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AnswerQuestionnaire
            arn={arn}
            organisation={getUserdata?.getCognitoAccount?.organisation}
            createdWorkloads={createdWorkloads}
            riskCounts={riskCounts}
            halfStageHriCount={halfStageHriCount}
            riskCountsDict={riskCountsDict}
            firstRiskCountsDict={firstRiskCountsDict}
            setRiskCountsDict={setRiskCountsDict}
            stateStage={stateStage}
            setStateStage={setStateStage}
            changeTab={(index: number) => {
              setValue(index);
              setCreatedWorkloads({});
              setRiskCountsDict({});
              setFirstRiskCountsDict({});
              setHalfStageHriCount(0);
              setStateStage({});
            }}
            setUnlockStep2={setUnlockStep2}
            process={process}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ListWorkloads
            arn={arn}
            updateWorkLoad={updateWorkLoad}
            setUnlockStep2={setUnlockStep2}
            setUnlockStep3={setUnlockStep3}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Sow />
        </TabPanel>
      </Box>
    </Container>
  );
}
