import { FC, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useListIacWorkloadsQuery } from "../../../generated";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Grid,
  Button,
  Container,
  Stack,
  LinearProgress,
  Pagination,
  InputLabel,
  FormControl,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import StyledSelect from "../../common/StyledSelect";
import StyledTableCell from "../../common/StyledTableCell";
import ListWorkLoadIacItem from "./ListWorkLoadIacItem";

interface ListWorkloadsIacProps {
  updateWorkLoad: (
    workLoadId: string,
    workLoadName: string,
    highRiskCount: number,
    initialHighRiskCount: number,
    awsWafrAccount: string,
  ) => void;
  setUnlockSteps: React.Dispatch<
    React.SetStateAction<{
      step2: boolean;
      step3: boolean;
      step4: boolean;
    }>
  >;
}

const ListWorkloadsIac: FC<ListWorkloadsIacProps> = ({
  updateWorkLoad,
  setUnlockSteps,
}: ListWorkloadsIacProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data, loading, error, refetch } = useListIacWorkloadsQuery({
    variables: {
      limit: 100, // value for 'limit'
      nextToken: "", // value for 'nextToken'
    },
    context: {
      apiName: "well_architected",
    },
  });

  //Handle that locks all steps
  const handleUnlockSteps = () => {
    setUnlockSteps((prevState) => ({
      ...prevState,
      step2: false, // Lock step 2
      step3: false, // lock step 3
      step4: false, // lock step 4
    }));
  };

  useEffect(() => {
    refetch({ limit: 100, nextToken: ""});
    handleUnlockSteps();
  }, [data, refetch]);

  if (loading) {
    return (
      <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
        <LinearProgress color="secondary" />
      </Stack>
    );
  }

  if (error) {
    return (
      <Typography
        component={"span"}
        variant="h6"
        color="text.primary"
        paragraph
      >
        error...
      </Typography>
    );
  }

  return (
    <Container maxWidth="lg" component={Paper}>
      <Grid container sx={{ mb: 2 }} spacing={2} alignItems="center">
        <Grid item md={10} justifyContent="flex-end">
          <Typography
            component={"span"}
            variant="h6"
            color="text.primary"
            paragraph
          >
            View in AWS Well-Architected Framework Console
          </Typography>
        </Grid>
        <Grid item md={2} alignItems="flex-end">
          <Button
            id="wat-setup-btn"
            type="submit"
            variant="contained"
            endIcon={<OpenInNewIcon />}
            href="//console.aws.amazon.com/wellarchitected/home"
            target={"_blank"}
            sx={{
              width: 157,
              height: 40,
              bgcolor: "secondary.main",
              "&:hover": {
                bgcolor: "secondary.main",
                color: "secondary.contrastText",
              },
            }}
          >
            View
          </Button>
        </Grid>
        <Grid item md={12}>
          <Paper
            component="form"
            elevation={0}
            sx={{
              p: "2px 4px",
              mb: 2,
              display: "flex",
              justifyContent: "flex-end",
              flexGrow: 1,
            }}
          >
            <InputLabel id="page-size" sx={{ alignSelf: "center", p: 1 }}>
              Show
            </InputLabel>
            <FormControl sx={{ m: 1, minWidth: 119 }} size="small">
              <StyledSelect
                labelId="page-size-select-label"
                id="page-size-select"
                defaultValue={rowsPerPage}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  setRowsPerPage(Number(e.target.value));
                  setPage(0);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </StyledSelect>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Workload Name</StyledTableCell>
              <StyledTableCell>Updated At</StyledTableCell>
              <StyledTableCell>Milestones</StyledTableCell>
              <StyledTableCell>HRI's</StyledTableCell>
              <StyledTableCell>Update</StyledTableCell>
              <StyledTableCell>Report</StyledTableCell>
              <StyledTableCell>Delete</StyledTableCell>
              <StyledTableCell sx={{ width: "5%" }}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.listIacWorkloads?.items
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((workload: any) => {
                let lens: string[] = [];
                let workloadlens: string = "wellarchitected";
                workload.Lenses.forEach(function (value: any) {
                  if (value === "wellarchitected") {
                    lens.push("Well-Architected");
                  }
                });
                return (
                  <ListWorkLoadIacItem
                    key={workload.WorkloadId}
                    workLoadId={workload.WorkloadId}
                    workLoadName={workload.WorkloadName}
                    updatedAt={workload.UpdatedAt}
                    updateWorkLoad={updateWorkLoad}
                    lenses={lens}
                    highRisks={workload.RiskCounts.HIGH}
                    workLoadlens={workloadlens}
                    accountIds={workload.AccountIds}
                    awsCreditAccount={workload.AwsCreditAccount}
                    awsWafrAccount={workload.AwsWafrAccount}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        flexDirection="row"
        justifyContent={"flex-end"}
        sx={{ p: 0, pt: 2, pb: 2 }}
      >
        <Pagination
          count={
            data?.listIacWorkloads?.items.length
              ? Math.ceil(data?.listIacWorkloads?.items.length / rowsPerPage)
              : 0
          }
          variant="outlined"
          sx={{
            color: "primary.main",
            borderColor: "primary.main",
          }}
          shape="rounded"
          page={page + 1}
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            setPage(value - 1);
          }}
        />
      </Grid>
    </Container>
  );
};

export default ListWorkloadsIac;
