import { FC, useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import AwsSvg from "../../assets/aws-icon";
import {
  Table,
  TableBody,
  TableRow,
  Box,
  Link,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { Row } from "@tanstack/react-table";
import { useGetControlQuery, useGetSupportControlQuery } from "../../generated";
import ListFindingsProps from "../../models/ListFindingsProps";
import StyledTableCell from "./StyledTableCell";
import Control from "../../models/Control";
import useSnackBars from "../../hooks/useSnackbar";
import Loader from "./Loader";
import CustomRemedation from "./CustomRemediation";
import ComplianceStatus from "./ComplianceStatus";
import RemediateFailedFindingsButton from "./RemediateFailedFindingsButton";
import useAccount from "../../hooks/useAccount";

const Finding: FC<any> = ({
  row,
  finding,
  changeStatus,
}: {
  finding: any;
  controlshlink: string;
  row: Row<Control>;
  customcontrol: boolean;
  changeStatus: (finding: any, status: string) => void;
}) => {
  const { setAlerts } = useSnackBars();

  return (
    <TableRow key={finding.Id}>
      <StyledTableCell key={`f-1`} colSpan={2} sx={{ justify: "center" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ComplianceStatus
            value={finding.Compliance.Status}
          ></ComplianceStatus>
        </Box>
      </StyledTableCell>
      <StyledTableCell key={`f-2`} colSpan={3} sx={{ paddingLeft: 0 }}>
        <Table>
          <TableBody sx={{ pl: 0 }}>
            <TableRow key={`${finding.Id}-2`}>
              <StyledTableCell
                colSpan={1}
                sx={{
                  borderBottom: 0,
                  padding: 0,
                  paddingLeft: 0,
                  verticalAlign: "top",
                }}
              >
                <Typography fontSize={"14px"}>Account ID</Typography>
              </StyledTableCell>
              <StyledTableCell
                colSpan={2}
                sx={{
                  borderBottom: 0,
                  padding: 0.25,
                  verticalAlign: "top",
                }}
              >
                <Typography fontSize={"14px"} sx={{ width: 280 }}>
                  {finding.AwsAccountId}
                </Typography>
              </StyledTableCell>
            </TableRow>
            {finding?.Resources && finding?.Resources[0]?.Name != "N/A" && (
              <TableRow key={`${finding.Id}-3`}>
                <StyledTableCell
                  colSpan={1}
                  sx={{
                    borderBottom: 0,
                    padding: 0,
                    paddingLeft: 0,
                    verticalAlign: "top",
                  }}
                >
                  <Typography fontSize={"14px"}>Name</Typography>
                </StyledTableCell>
                <StyledTableCell
                  colSpan={2}
                  sx={{
                    borderBottom: 0,
                    padding: 0.25,
                    width: 225,
                    verticalAlign: "top",
                  }}
                >
                  <Typography fontSize={"14px"} sx={{ width: 280 }}>
                    {finding?.Resources ? finding?.Resources[0]?.Name : ""}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            )}
            <TableRow key={`${finding.Id}-4`}>
              <StyledTableCell
                colSpan={1}
                sx={{
                  borderBottom: 0,
                  padding: 0,
                  paddingLeft: 0,
                  verticalAlign: "top",
                }}
              >
                <Typography fontSize={"14px"}>Service ID</Typography>
              </StyledTableCell>
              <StyledTableCell
                colSpan={2}
                sx={{
                  borderBottom: 0,
                  padding: 0.25,
                  width: 225,
                  verticalAlign: "top",
                }}
              >
                <Typography fontSize={"14px"} sx={{ width: 280 }}>
                  {finding?.Resources ? finding?.Resources[0]?.Id : ""}
                </Typography>
              </StyledTableCell>
            </TableRow>
            <TableRow key={`${finding.Id}-5`}>
              <StyledTableCell
                colSpan={1}
                sx={{
                  borderBottom: 0,
                  padding: 0,
                  paddingLeft: 0,
                  verticalAlign: "top",
                }}
              >
                <Typography fontSize={"14px"}>Finding ID</Typography>
              </StyledTableCell>
              <StyledTableCell
                colSpan={2}
                sx={{
                  borderBottom: 0,
                  padding: 0.25,
                  width: 200,
                  verticalAlign: "top",
                }}
              >
                <Typography fontSize={"14px"} sx={{ width: 280 }}>
                  {finding?.Id?.split("/").reverse()[0]}
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledTableCell>
      <StyledTableCell key={`f-5`} colSpan={2} sx={{ verticalAlign: "top" }}>
        <>
          <CustomRemedation
            handleConfirm={(status: boolean) => {
              if (status) {
                changeStatus(finding, "Resolved");
                setAlerts([
                  {
                    severity: "success",
                    msg: "Manual continuous compliance submitted.",
                  },
                ]);
              } else {
                setAlerts([
                  {
                    severity: "error",
                    msg: "Manual continuous compliance submission failed.",
                  },
                ]);
              }
            }}
            row={row}
            finding={finding}
          />
        </>
      </StyledTableCell>
      <StyledTableCell colSpan={1} />
    </TableRow>
  );
};

const FindingFirstRow: FC<any> = ({
  findings,
  customcontrol,
  controlshlink,
}: {
  findings: any[];
  customcontrol: boolean;
  controlshlink: string;
}) => {
  const finding = findings[0];
  return (
    <TableRow key={`${finding?.Id}-first-row`}>
      <StyledTableCell colSpan={2} />
      <StyledTableCell key={`f-2`} colSpan={3} sx={{ paddingLeft: 0 }}>
        <Table>
          <TableBody sx={{ pl: 0 }}>
            <TableRow key={`${finding.Id}-2`}>
              <StyledTableCell
                colSpan={1}
                sx={{
                  borderBottom: 0,
                  padding: 0,
                  paddingLeft: 10,
                  verticalAlign: "top",
                  width: 115,
                }}
              >
                <Typography fontSize={"14px"}>Title</Typography>
              </StyledTableCell>
              <StyledTableCell
                colSpan={2}
                sx={{
                  borderBottom: 0,
                  padding: 0.25,
                  width: 200,
                  verticalAlign: "top",
                }}
              >
                <Typography fontSize={"14px"} sx={{ width: 280 }}>
                  {finding.Title}
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledTableCell>
      <StyledTableCell key={`f-3`} colSpan={2} sx={{ verticalAlign: "center" }}>
        {!customcontrol && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 2,
            }}
            flexDirection={"column"}
          >
            <Button
              href={finding?.ProductFields?.RecommendationUrl || ""}
              variant="outlined"
              target={"_blank"}
              sx={{ width: "100%" }}
              startIcon={<AwsSvg></AwsSvg>}
            >
              <Typography fontSize={"10px"} sx={{ textAlign: "left" }}>
                View Remediation Docs
              </Typography>
            </Button>
            <Button
              href={controlshlink || ""}
              variant="outlined"
              target={"_blank"}
              startIcon={<AwsSvg></AwsSvg>}
              sx={{ width: "100%", mt: 1 }}
            >
              <Typography fontSize={"10px"} sx={{ textAlign: "left" }}>
                View in Security Hub
              </Typography>
            </Button>
          </Box>
        )}
      </StyledTableCell>
      <StyledTableCell colSpan={1} />
    </TableRow>
  );
};

const FindingsTable: FC<any> = ({
  row,
  loading,
  findings,
  controlshlink,
  customcontrol = false,
  changeStatus,
}: {
  findings: [];
  controlshlink: string;
  loading: boolean;
  row: Row<Control>;
  customcontrol: boolean;
  changeStatus: (finding: any, status: string) => void;
}) => {
  const accounts = useRef<string[]>([]);
  const regions = useRef<string[]>([]);
  const [isCalculating, setIsCalculating] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState<string>("select");
  const [selectedRegion, setSelectedRegion] = useState<string>("select");

  useEffect(() => {
    if (!loading && findings && findings?.length > 0) {
      findings.forEach((finding: any) => {
        if (!accounts.current.includes(finding?.AwsAccountId)) {
          accounts.current = [...accounts.current, finding?.AwsAccountId];
        }
        if (
          finding?.Resources &&
          finding?.Resources[0]?.Id &&
          !regions.current.includes(finding?.Resources[0]?.Id.split(":")[3])
        ) {
          regions.current = [
            ...regions.current,
            finding?.Resources[0]?.Id.split(":")[3],
          ];
        }
      });
      setIsCalculating(false);
    } else if (!loading && findings && findings?.length === 0) {
      setIsCalculating(false);
    } else {
      setIsCalculating(true);
    }
  }, [findings, loading]);

  return isCalculating ? (
    <TableRow key={`${row.id}-expanded`}>
      <StyledTableCell colSpan={row.getVisibleCells().length}>
        <Loader></Loader>
      </StyledTableCell>
    </TableRow>
  ) : (
    <>
      {accounts.current?.length > 0 && findings?.length > 0 && (
        <>
          <TableRow key={row.original.controlname}>
            <StyledTableCell key={`0-h-1`} colSpan={3}>
              <RemediateFailedFindingsButton
                row={row}
                accountRefIds={accounts.current}
                findings={findings}
                loading={loading}
              ></RemediateFailedFindingsButton>
            </StyledTableCell>
            <StyledTableCell key={`0-h-2`} colSpan={4}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Select
                  labelId="filter-by-account"
                  id="filter-by-account"
                  value={selectedAccount}
                  label="Filter by AWS Account"
                  size="small"
                  onChange={(e) => {
                    setSelectedAccount(e.target.value as string);
                  }}
                >
                  <MenuItem disabled={true} value={"select"}>
                    Filter by AWS Account
                  </MenuItem>
                  {accounts.current.map((member_account: string) => (
                    <MenuItem value={member_account}>{member_account}</MenuItem>
                  ))}
                </Select>
                <Select
                  labelId="filter-by-region"
                  id="filter-by-region"
                  value={selectedRegion}
                  label="Filter by AWS region"
                  size="small"
                  onChange={(e) => {
                    setSelectedRegion(e.target.value as string);
                  }}
                  sx={{ ml: 2 }}
                >
                  <MenuItem disabled={true} value={"select"}>
                    Filter by AWS Region
                  </MenuItem>
                  {regions.current.map((region: string) => (
                    <MenuItem value={region}>{region}</MenuItem>
                  ))}
                </Select>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Link
                  component="button"
                  variant="body2"
                  color="secondary.main"
                  sx={{ fontSize: "12px", pt: 0.1 }}
                  onClick={() => {
                    setSelectedRegion("select");
                    setSelectedAccount("select");
                  }}
                >
                  Clear Selection
                </Link>
              </Box>
            </StyledTableCell>
            <StyledTableCell colSpan={1} />
          </TableRow>
          <FindingFirstRow
            findings={findings}
            customcontrol={customcontrol}
            controlshlink={controlshlink}
          ></FindingFirstRow>
        </>
      )}
      {findings &&
        findings
          ?.filter((finding: any) => {
            let isAllowedAccount = true;
            let isAllowedRegion = true;

            if (selectedAccount !== "select") {
              isAllowedAccount = finding?.AwsAccountId === selectedAccount;
            }
            if (selectedRegion !== "select") {
              isAllowedRegion =
                finding?.Resources &&
                finding?.Resources[0]?.Id.split(":")[3] === selectedRegion;
            }
            return isAllowedAccount && isAllowedRegion;
          })
          .map((finding: any) => {
            return (
              <Finding
                row={row}
                finding={finding}
                controlshlink={controlshlink}
                customcontrol={customcontrol}
                changeStatus={changeStatus}
              ></Finding>
            );
          })}
      {findings && findings.length === 0 && (
        <>
          <TableRow key={row.original.compliancestatus}>
            <StyledTableCell key={`0-f-1`} colSpan={2}></StyledTableCell>
            <StyledTableCell key={`0-f-2`} colSpan={1}>
              No Findings
            </StyledTableCell>
            <StyledTableCell
              key={`0-f-3`}
              colSpan={1}
              sx={{ verticalAlign: "top" }}
            ></StyledTableCell>
            <StyledTableCell
              key={`0-f-4`}
              colSpan={1}
              sx={{ verticalAlign: "top" }}
            >
              {!customcontrol && (
                <Link
                  variant="body2"
                  href={controlshlink || ""}
                  target={"_blank"}
                  sx={{ textDecoration: "none" }}
                >
                  <Box
                    sx={{
                      display: "flex ",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    flexDirection={"column"}
                  >
                    <AwsSvg></AwsSvg>
                    <Typography fontSize={"14px"} sx={{ textAlign: "center" }}>
                      Security Hub Findings
                    </Typography>
                  </Box>
                </Link>
              )}
            </StyledTableCell>
            <StyledTableCell
              key={`0-f-5`}
              colSpan={2}
              sx={{ verticalAlign: "top" }}
            ></StyledTableCell>
            <StyledTableCell
              key={`0-f-6`}
              colSpan={1}
              sx={{ verticalAlign: "top" }}
            ></StyledTableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

const RenderAwsControlFindings: FC<any> = ({
  row,
  arn,
  updateData,
}: ListFindingsProps) => {
  const { accountIdRef, getFilteredConnectedAccounts } = useAccount();
  const { data, loading, refetch } = useGetControlQuery({
    variables: {
      arn: arn,
      controlname: row.original.controlname,
      connectedAccounts: getFilteredConnectedAccounts(),
    },
  });
  const [findings, setFindings] = useState<any>([]);
  useEffect(() => {
    refetch();
  }, [arn, accountIdRef]);

  useEffect(() => {
    setFindings(data?.getControl?.findings);
    updateData(
      [row.original.controlname],
      "compliancestatus",
      data?.getControl?.compliancestatus || row.original.compliancestatus
    );
  }, [data]);

  return (
    <FindingsTable
      row={row}
      findings={findings}
      loading={loading}
      controlshlink={data?.getControl?.controlshlink}
      customcontrol={false}
      changeStatus={(changedFinding: any, status: string) => {
        let newFindings = findings.map((finding: any) => {
          if (finding.Id === changedFinding.Id) {
            finding = {
              ...finding,
              Workflow: {
                Status: status,
              },
            };
          }
          return finding;
        });
        setFindings(newFindings);
      }}
    ></FindingsTable>
  );
};

const RenderSup1ControlFindings: FC<any> = ({
  row,
  arn,
  updateData,
}: ListFindingsProps) => {
  const { data, loading, refetch } = useGetSupportControlQuery({
    variables: {
      arn: arn,
    },
  });

  useEffect(() => {
    refetch();
  }, [arn]);

  useEffect(() => {
    updateData(
      [row.original.controlname],
      "compliancestatus",
      data?.getSupportControl?.compliancestatus || row.original.compliancestatus
    );
  }, [data]);

  return (
    <FindingsTable
      row={row}
      findings={data?.getSupportControl?.findings}
      controlshlink={""}
      loading={loading}
      customcontrol={true}
    ></FindingsTable>
  );
};

const Findings: FC<any> = ({ row, arn, updateData }: ListFindingsProps) => {
  if (row.original.controlname.includes("6PL.")) {
    if (row.original.controlname.includes("6PL.SUP.1")) {
      return (
        <RenderSup1ControlFindings
          row={row}
          arn={arn}
          updateData={updateData}
        ></RenderSup1ControlFindings>
      );
    } else {
      return <></>;
    }
  } else {
    return (
      <RenderAwsControlFindings
        row={row}
        arn={arn}
        updateData={updateData}
      ></RenderAwsControlFindings>
    );
  }
};

export default Findings;
