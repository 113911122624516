import { useEffect, useState } from "react";
import { useGetUrlQuery } from "../../generated";
import { LoadingButton } from "@mui/lab";
import { OpenInNew } from "@mui/icons-material";
import { Form } from "formik";
import { FormControl } from "@mui/material";

interface LaunchSixPillarsTemplateProps {
  template: string;
  defaultregion: string;
  externalId: string;
}

export default function LaunchSixPillarsTemplate({
  template,
  defaultregion,
  externalId,
}: LaunchSixPillarsTemplateProps) {
  const [launchurl, setLauchurl] = useState("");
  //Get CFN Template for six pillars stack and open it in new tab window
  const { data, loading, error } = useGetUrlQuery({
    variables: {
      template: template, // value for 'template'
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  //Launch CFN Template for six pillars stack and open it in new tab window
  const launchCfnTemplate = (uuid: string, data: any) => {
    let launchurl = `https://console.aws.amazon.com/cloudformation/home?region=${defaultregion}#/stacks/create/review?stackName=six-pillars&templateURL=${encodeURIComponent(
      data?.getTemplateS3URL
    )}&param_ExternalId=${uuid}&param_EnableSixPillarsDeployAccess=YES`;
    setLauchurl(launchurl);
  };

  //Initiate CFN Stack Role Deployment
  useEffect(() => {
    launchCfnTemplate(externalId, data);
  }, [data, loading]);

  return (
    <FormControl fullWidth>
      <LoadingButton
        loading={loading}
        href={launchurl}
        target="_blank"
        variant="contained"
        sx={{
          bgcolor: "secondary.main",
          "&:hover": {
            bgcolor: "secondary.main",
            color: "secondary.contrastText",
          },
        }}
        endIcon={<OpenInNew />}
        loadingPosition="end"
      >
        Open Role Template
      </LoadingButton>
    </FormControl>
  );
}
