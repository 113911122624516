import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import MultiInputControls from "../../../models/MultiInputControls";
import {
  Alert,
  AlertColor,
  IconButton,
  Switch,
  TableBody,
  TableRow,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import StyledTableCell from "../StyledTableCell";
import { useGetControlQuery } from "../../../generated";
import useAccount from "../../../hooks/useAccount";
interface CloudwatchSwitchProps {
  controlname: string;
  formikCloudwatchControls: string[];
  handleCheckboxChange: (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

interface Finding {
  status: string;
  title: string;
  severity: AlertColor;
}

const CloudwatchSwitch: FC<CloudwatchSwitchProps> = ({
  controlname,
  formikCloudwatchControls,
  handleCheckboxChange,
}) => {
  //Query to get the sec hub findings
  const { arn, defaultregion: region } = useAccount();
  const [finding, setFinding] = useState<Finding>({
    status: "UNKNOWN",
    title: "",
    severity: "info",
  });
  const { data, loading } = useGetControlQuery({
    variables: {
      arn,
      controlname,
      connectedAccounts: [
        {
          accountIdRef: arn.split(":")[4],
          region,
        },
      ],
    },
  });

  useEffect(() => {
    const findings = data?.getControl?.findings || [];
    if (!loading && findings.length > 0) {
      const status = findings[0].Compliance?.Status || "UNKNOWN";
      let severity: AlertColor = "info";
      if (status === "FAILED") {
        severity = "error";
      } else if (status === "UNKNOWN") {
        severity = "warning";
      } else if (status === "PASSED") {
        severity = "success";
      } else {
        severity = "info";
      }
      setFinding({
        status,
        title: data?.getControl?.controldescription || "",
        severity,
      });
    }
    if (!loading && findings.length === 0) {
      setFinding({
        status: "No Findings",
        title: data?.getControl?.controldescription || "",
        severity: "success",
      });
    }
    if (loading) {
      setFinding({
        status: "Loading ...",
        title: "",
        severity: "info",
      });
    }
  }, [data, loading]);

  return (
    <>
      <StyledTableCell size="small" sx={{ p: 0 }}>
        {controlname}
        <Tooltip title={finding.title}>
          <IconButton>
            <InfoIcon fontSize="small" color="info" />
          </IconButton>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell size="small" sx={{ p: 0 }}>
        <Alert severity={finding.severity} sx={{ backgroundColor: "white" }}>
          {finding.status}
        </Alert>
      </StyledTableCell>
      <StyledTableCell size="small" sx={{ p: 0 }}>
        <Tooltip title="Enable/Disable - CloudWatch Metric and Alarm">
          <Switch
            checked={formikCloudwatchControls.includes(controlname)}
            value={controlname}
            name={controlname}
            disabled={loading || finding.status !== "FAILED"}
            size="small"
            onChange={handleCheckboxChange}
            color="success"
          />
        </Tooltip>
      </StyledTableCell>
    </>
  );
};
interface CloudwatchSwitchesProps {
  formikCloudwatchControls: string[];
  setFieldValue: (field: string, controls: string[]) => void;
}

const CloudwatchSwitches: FC<CloudwatchSwitchesProps> = ({
  formikCloudwatchControls,
  setFieldValue,
}) => {
  const controls: Array<string> = Object.values(MultiInputControls);
  const rows = [];

  const handleCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const control: string = e?.target?.name || "";
      const cloudwatchControls = new Set<string>(formikCloudwatchControls);
      if (checked) {
        cloudwatchControls.add(control);
      } else {
        cloudwatchControls.delete(control);
      }
      setFieldValue(
        "cloudwatchControls",
        Array.from(cloudwatchControls.values())
      );
    },
    [formikCloudwatchControls, setFieldValue]
  );

  for (let i = 0; i < controls.length; i += 2) {
    rows.push(
      <TableRow>
        <CloudwatchSwitch
          controlname={controls[i]}
          formikCloudwatchControls={formikCloudwatchControls}
          handleCheckboxChange={handleCheckboxChange}
        />
        {i + 1 >= controls.length ? (
          <StyledTableCell colSpan={3} size="small" sx={{ p: 0 }} />
        ) : (
          <CloudwatchSwitch
            controlname={controls[i + 1]}
            formikCloudwatchControls={formikCloudwatchControls}
            handleCheckboxChange={handleCheckboxChange}
          />
        )}
      </TableRow>
    );
  }
  return <TableBody>{rows}</TableBody>;
};

export default CloudwatchSwitches;
