import { useEffect, useState } from "react";
import { useGetRemediationStatusLazyQuery } from "../generated";

interface RemediationProgressProps {
  arn: string;
}

const useGetRemediationProgress = ({ arn }: RemediationProgressProps) => {
  const [remediationProgress, setRemeidationProgress] = useState(0);
  const [remediationMessage, setRemediationMessage] = useState("");
  const [getRemediationStatus] = useGetRemediationStatusLazyQuery();
  const [executionArn, setExecutionArn] = useState("");
  useEffect(() => {
    if (executionArn === "") {
      setRemeidationProgress(0);
      setRemediationMessage("");
      return;
    }

    if (remediationProgress < 20) {
      setRemeidationProgress(20);
    }
    const interval = setInterval(() => {
      // Fetch the remediation progress

      getRemediationStatus({
        variables: { arn, executionArn },
        context: {
          apiName: "automation_approval",
        },
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
      }).then((response) => {
        let tasksCount =
          response.data?.getRemediationStatus?.tasks?.length || 0;
        let newProgress = 20 + (tasksCount / 6) * 80;
        if (newProgress >= 100) {
          newProgress = 100;
          if (interval) {
            clearInterval(interval);
          }
        }
        setRemeidationProgress(newProgress);
        setRemediationMessage(
          response.data?.getRemediationStatus?.status ||
            response.data?.getRemediationStatus?.error ||
            ""
        );
      });
    }, 10000);

    return () => clearInterval(interval);
  }, [executionArn]);

  return { remediationProgress, remediationMessage, setExecutionArn };
};

export default useGetRemediationProgress;
