import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import { OnResultDocument, useCreateAccountMutation } from "../../../generated";
import { useApolloClient } from "@apollo/client";
import { useFormik } from "formik";
import * as yup from "yup";
import useAccount from "../../../hooks/useAccount";
import useRegions from "../../../hooks/useRegions";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useSnackBars from "../../../hooks/useSnackbar";

interface ManualCrossAccountProps {
  accountType: string;
  deploymentType: string;
  setValue: any;
  setAccountCreated: any;
  setTabSwitch: any;
}

const style = {
  position: "absolute" as "absolute",
  transform: "translate(-50%, -50%)",
  width: "550px",
  height: "Hug (268px)",
  top: "50%",
  left: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  radius: "8px",
  padding: "32px",
  gap: "32px",
};

const accountNameRegExp = /^[a-zA-Z0-9_-\s+]{1,32}$/;
const accountIdRegExp = /[0-9]{12}/;

const validationSchema = yup.object({
  accountName: yup
    .string()
    .matches(accountNameRegExp, "Account Name is not valid")
    .required("Account Name is required"),
  accountId: yup
    .string()
    .matches(accountIdRegExp, "Account Id is not valid")
    .required("Account Id is required"),
});

enum ViewStep {
  CREATE_FORM,
  VALIDATE_MODAL,
  TEMPLATE_MODAL,
}

enum DataStatus {
  INITIAL,
  VALIDATING_ACCOUNT_ID,
  CREATING_ACCOUNT,
  ACCOUNT_CREATED,
  PREPARING_TEMPLATE,
  ERROR,
}

export default function ManualCrossAccount({
  accountType,
  deploymentType,
  setAccountCreated,
}: ManualCrossAccountProps) {
  const regions = useRegions();

  const [accountRegion, setAccountRegion] = useState("");
  const navigate = useNavigate();
  const { setAlerts } = useSnackBars();
  const { accounts, refreshAccounts, setAccount } = useAccount();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    viewStep: ViewStep.CREATE_FORM,
    dataStatus: DataStatus.INITIAL,
  });

  const client = useApolloClient();

  const formik = useFormik({
    initialValues: {
      accountName: "",
      accountId: "",
      externalId: "",
      snsTopicArn: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      startCreateAccountPromise(values)
        .then(waitForAccountCreationResult)
        .catch(() => {
          setStatus({
            viewStep: ViewStep.CREATE_FORM,
            dataStatus: DataStatus.ERROR,
          });
        });
    },
  });

  //Create Account
  const [createAccountMutation] = useCreateAccountMutation();

  const handleRegion = (event: SelectChangeEvent) => {
    setAccountRegion(event.target.value as string);
  };

  const waitForAccountCreationResult = (startCreateAccountResponse: any) => {
    client
      ?.subscribe({
        query: OnResultDocument,
        variables: {
          id: startCreateAccountResponse?.data?.createAccount?.id,
        },
      })
      .subscribe(({ data }: any) => {
        if (data?.onResult?.status === "COMPLETE") {
          setAccountCreated(true);
          refreshAccounts("");
          setAlerts([
            {
              msg: "Redirecting to rules page in 10 seconds. It could take 24hrs to populate control compliance status and findings.",
              severity: "success",
            },
          ]);
          const account = accounts.find((i: any) =>
            i.arn.includes(formik.values.accountId)
          );
          if (account) {
            setAccount({
              arn: account.arn,
              defaultregion: account.defaultregion,
              accounttype: account.accounttype,
              member_accounts: account.member_accounts,
              member_regions: account.member_regions,
              connectedAccounts: account.connectedAccounts,
              accountIdRef: "organisation",
            });
          }
          setTimeout(() => {
            navigate("/rules");
          }, 10);
        } else {
          setStatus({
            viewStep: ViewStep.CREATE_FORM,
            dataStatus: DataStatus.ERROR,
          });
        }
        setLoading(false);
      });
  };

  const startCreateAccountPromise = (values: any) => {
    setLoading(true);
    return createAccountMutation({
      variables: {
        arn: "arn:aws:iam::" + values.accountId + ":role/six-pillars-role", // value for 'arn'
        accessnickname: values.accountName, // value for 'accessnickname'
        accounttype: accountType, // value for 'accounttype'
        defaultregion: accountRegion, // value for 'defaultregion'
        externalid: values.externalId, // value for 'externalid'
        snstopicarn: values.snsTopicArn, // value for 'snsarn'
        deploymenttype: deploymentType, // value for 'deploymenttype'
      },
      context: {
        apiName: "user_deploy_process",
      },
    });
  };

  return (
    <>
      <form>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={4}>
            <TextField
              required
              fullWidth
              id="accountName"
              placeholder="Name this deployment"
              label="Name this deployment"
              helperText="1-32 characters, lower & upper case, numeric, dashes and spaces."
              value={formik.values.accountName}
              error={
                formik.touched.accountName && Boolean(formik.errors.accountName)
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item md={8}></Grid>
          <Grid item md={4}>
            <TextField
              required
              fullWidth
              id="accountId"
              placeholder="AWS Account ID"
              label="AWS Account ID"
              value={formik.values.accountId}
              error={
                formik.touched.accountId && Boolean(formik.errors.accountId)
              }
              helperText={formik.touched.accountId && formik.errors.accountId}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item md={8}></Grid>
          <Grid item md={4}>
            <TextField
              required
              fullWidth
              id="externalId"
              placeholder="External ID"
              label="External ID"
              value={formik.values.externalId}
              error={
                formik.touched.externalId && Boolean(formik.errors.externalId)
              }
              helperText={formik.touched.externalId && formik.errors.externalId}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item md={8}></Grid>
          <Grid item md={4}>
            <TextField
              required
              fullWidth
              id="snsTopicArn"
              placeholder="SNS Topic ARN"
              label="SNS Topic ARN"
              value={formik.values.snsTopicArn}
              error={
                formik.touched.snsTopicArn && Boolean(formik.errors.snsTopicArn)
              }
              helperText={
                formik.touched.snsTopicArn && formik.errors.snsTopicArn
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item md={8}></Grid>
          <Grid item md={4}>
            <FormControl fullWidth>
              <InputLabel id="dep_arn">Region</InputLabel>
              <Select
                required
                id="dep_region"
                value={accountRegion}
                label="Region"
                onChange={handleRegion}
              >
                {Array.from(regions.keys()).map((region: any) => {
                  return (
                    <MenuItem key={region} value={region}>
                      {regions.get(region)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={8}></Grid>
          <Grid item md={12}></Grid>
          <Grid item xs md={4} justifyContent="flex-end">
            <Stack direction="row">
              <LoadingButton
                id="automate-setup-btn"
                variant="contained"
                onClick={() => {
                  formik.handleSubmit();
                }}
                disabled={loading}
                sx={{
                  width: 157,
                  height: 40,
                  bgcolor: "secondary.main",
                  "&:hover": {
                    bgcolor: "secondary.main",
                    color: "secondary.contrastText",
                  },
                }}
              >
                Deploy
              </LoadingButton>
            </Stack>
          </Grid>
          <Grid item md={12}></Grid>
          <Grid item md={12}>
            {status.dataStatus === DataStatus.ERROR ? (
              <Alert severity="error">Account Creation Failed</Alert>
            ) : null}
            {status.dataStatus === DataStatus.ACCOUNT_CREATED ? (
              <Alert severity="success">Account successfully created</Alert>
            ) : null}
          </Grid>

          <Grid item md={12}>
            <Typography variant="body2" color="secondary.main" paragraph>
              Note: Please note that due to deployment of additional AWS native
              security services as part of 6pillars' AUTOMATE+, you may notice
              an incremental increase in your AWS consumption costs.
            </Typography>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
