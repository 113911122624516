import React, { FC, useState, useMemo, useEffect, useCallback } from "react";
import Typography from "@mui/material/Typography";
import { useListSecurityControlsQuery } from "../../hooks/useListSecurityControlsQuery";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Pagination,
  SvgIcon,
  SelectChangeEvent,
  IconButton,
  Container,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getExpandedRowModel,
  FilterFn,
  SortingFn,
  sortingFns,
  Row,
  SortingState,
} from "@tanstack/react-table";

import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";
import Findings from "./../common/Findings";
import Filter from "./Filter";
import Control from "./../../models/Control";
import StyledTableCell from "../common/StyledTableCell";
import {
  IndeterminateCheckbox,
  IndeterminateAllCheckbox,
} from "../common/Checkboxes";
import { AlertSwitch, AutomateSwitch } from "./../common/Switches";
import StyledSelect from "../common/StyledSelect";
import MultiRemediateSelect from "./../common/MultiRemediateSelect";
import Search from "./../common/Search";
import useAccount from "./../../hooks/useAccount";
import Loader from "./../common/Loader";
import Heading from "../common/Heading";
import ComplianceStatus from "../common/ComplianceStatus";
import { useSearchParams } from "react-router-dom";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const canAutoRemediate = (row: Row<Control>) => {
  return (
    (row.original.compliancestatus === "PASSED" &&
      row.original.intrusive === "INTRUSIVE") ||
    (row.original.intrusive === "INTRUSIVE" &&
      row.original.remediatestatus === "ENABLED") ||
    row.original.intrusive === "NON-INTRUSIVE"
  ); // Non-compliant and disruptive -> no auto-remediation or no one off remediation
};


const ListSecuirtyControls: FC = () => {
  let [searchParams, setSearchParams] = useSearchParams({
    complianceStandard: "",
    destructive: "",
    compliant: "",
    search: "",
    page: "1",
  });
  const { arn, featureAllowed, accountIdRef, getFilteredConnectedAccounts } =
    useAccount();
  const [rowSelection, setRowSelection] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "compliancestatus",
      desc: false,
    },
  ]);

  const updateFilter = useCallback(
    (filter: any) => {
      setSearchParams({
        complianceStandard: filter.complianceStandard,
        destructive: filter.destructive,
        compliant: filter.compliant,
        search: searchParams.get("search") || "",
      });
    },
    [searchParams]
  );

  const updateSearch = useCallback(
    (search: string) => {
      setSearchParams({
        complianceStandard: searchParams.get("complianceStandard") || "",
        destructive: searchParams.get("destructive") || "",
        compliant: searchParams.get("compliant") || "",
        search,
      });
    },
    [searchParams]
  );

  const columns = useMemo<ColumnDef<Control>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateAllCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }: { row: Row<Control> }) =>
          row.original.remediatestatus !== "N/A" && canAutoRemediate(row) ? (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          ) : (
            ""
          ),
      },
      {
        id: "controlid",
        header: ({ table }) => {
          return (
            <Box sx={{ width: 140 }}>
              <Typography fontSize={"14px"}>{"Control ID"}</Typography>
            </Box>
          );
        },
        accessorKey: "controlname", //using accessorKey dot notation to access nested data
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      },
      {
        accessorKey: "controldescription", //using accessorKey dot notation to access nested data
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
        header: ({ table }) => {
          return (
            <Box sx={{ width: 290 }}>
              <Typography fontSize={"14px"}>{"Description"}</Typography>
            </Box>
          );
        },
        cell: ({ row, getValue }: any) => {
          return (
            <Box sx={{ width: 290 }}>
              <Typography
                fontSize={"14px"}
                dangerouslySetInnerHTML={{ __html: getValue() }}
              ></Typography>
            </Box>
          );
        },
      },
      {
        header: ({ table }) => {
          return (
            <Box sx={{ width: 72 }}>
              <Typography fontSize={"14px"}>{"Compliance"}</Typography>
            </Box>
          );
        },
        accessorKey: "compliancestatus",
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
        cell: ({ row, getValue }: any) => {
          let value = getValue();
          return <ComplianceStatus value={value}></ComplianceStatus>;
        },
      },
      {
        header: ({ table }) => {
          return (
            <Box sx={{ width: 80 }}>
              <Typography fontSize={"14px"}>{"Severity"}</Typography>
            </Box>
          );
        },
        accessorKey: "severity",
        filterFn: "fuzzy",
        sortingFn: severityFuzzySort,
        cell: ({ row, getValue }: any) => {
          let value = getValue();
          let color = "text";
          if (value === "MEDIUM") {
            color = "secondary.main";
          } else if (value === "LOW") {
            color = "success.main";
          } else if (value === "HIGH" || value === "CRITICAL") {
            color = "error.main";
          }
          return (
            <Box
              key={row.id}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                width: "95px",
              }}
            >
              {value !== "UNKNOWN" ? (
                <WarningAmberIcon sx={{ color, mr: 0.5 }}></WarningAmberIcon>
              ) : (
                <></>
              )}
              <Typography fontSize={"14px"} color={color}>
                {value}
              </Typography>
            </Box>
          );
        },
      },
      {
        id: "Control",
        header: ({ table }) => {
          return (
            <Box sx={{ width: 70 }}>
              <Typography fontSize={"14px"}>{"Control"}</Typography>
            </Box>
          );
        },
        accessorKey: "alertstatus",
        enableSorting: false,
        cell: ({ row, getValue }: any) => (
          <AlertSwitch
            row={row}
            getValue={getValue}
            updateData={updateData}
          ></AlertSwitch>
        ),
      },
      {
        id: "Auto-Remediation",
        accessorKey: "remediatestatus",
        enableSorting: false,
        header: ({ table }) => {
          return (
            <Box sx={{ width: 110 }}>
              <Typography fontSize={"14px"}>
                {"Continuous Compliance"}
              </Typography>
            </Box>
          );
        },
        cell: ({ row, getValue }: any) => {
          return featureAllowed("continuous compliance") ? (
            <AutomateSwitch
              row={row}
              getValue={getValue}
              updateData={updateData}
            ></AutomateSwitch>
          ) : (
            <>N/A</>
          );
        },
      },
      {
        id: "expander",
        header: ({ table }) => {
          return <Box sx={{ width: 50 }}></Box>;
        },
        cell: ({ row }: { row: Row<Control> }) => {
          return row.getCanExpand() ? (
            <IconButton
              key={row.id}
              onClick={row.getToggleExpandedHandler()}
              sx={{
                cursor: "pointer",
              }}
              aria-label="delete"
              size="small"
            >
              {row.getIsExpanded() ? (
                <ExpandLessOutlinedIcon fontSize="inherit" color="secondary" />
              ) : (
                <ExpandMoreOutlinedIcon fontSize="inherit" color="secondary" />
              )}
            </IconButton>
          ) : (
            ""
          );
        },
      },
    ],
    []
  );

  const { controls, loading, error, updateData, refetch } =
    useListSecurityControlsQuery({
      arn,
      connectedAccounts: getFilteredConnectedAccounts(),
    });
  const [tableRows, setTableRows] = useState([]);

  const table = useReactTable({
    data: tableRows,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    defaultColumn: {
      size: 1,
      minSize: 1,
      maxSize: 2,
    },
    initialState: {
      pagination: {
        pageSize: 20,
        pageIndex: 0,
      },
    },
    state: {
      sorting,
      rowSelection,
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    getRowCanExpand: (row: Row<Control>) => {
      return true;
    },
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
  });

  const isEmpty = useCallback((str: string | null) => !str?.length, []);

  useEffect(() => {
    setTableRows([]);
    refetch();
  }, [accountIdRef]);

  useEffect(() => {
    const complianceStandard = searchParams.get("complianceStandard");
    const destructive = searchParams.get("destructive");
    const compliant = searchParams.get("compliant");

    let data: any = controls.filter((control: any) => {
      if (
        !isEmpty(complianceStandard) ||
        !isEmpty(destructive) ||
        !isEmpty(compliant)
      ) {
        let criteriaOne = true;
        let criteriaTwo = true;
        let criteriaThree = true;

        if (!isEmpty(complianceStandard)) {
          criteriaOne = false;
        }

        if (!isEmpty(destructive)) {
          criteriaTwo = false;
        }

        if (!isEmpty(compliant)) {
          criteriaThree = false;
        }

        if (
          !isEmpty(complianceStandard) &&
          Array.isArray(control?.customStandard) &&
          control?.customStandard.length > 0 &&
          control?.customStandard.toString().includes(complianceStandard)
        ) {
          criteriaOne = true;
        }

        if (
          !isEmpty(destructive) &&
          control?.intrusive === "NON-INTRUSIVE" &&
          control?.playbooks === "YES" &&
          destructive === "NO"
        ) {
          criteriaTwo = true;
        }

        if (
          !isEmpty(destructive) &&
          control?.intrusive === "INTRUSIVE" &&
          control?.playbooks === "YES" &&
          destructive === "YES"
        ) {
          criteriaTwo = true;
        }

        if (!isEmpty(compliant) && control?.compliancestatus === compliant) {
          criteriaThree = true;
        }
        return criteriaOne && criteriaTwo && criteriaThree;
      } else {
        return true;
      }
    });

    const searchValue = searchParams.get("search");
    if (searchValue !== null && searchValue !== "") {
      const search = searchValue.toLowerCase();
      data = data.filter((control: any) => {
        if (control?.controlname.toLowerCase().includes(search)) return true;
        else if (control?.controldescription.toLowerCase().includes(search))
          return true;
        else if (control?.compliancestatus.toLowerCase().includes(search))
          return true;
        else if (control?.severity.toLowerCase().includes(search)) return true;
        else if (control?.alertstatus.toLowerCase().includes(search))
          return true;
        else if (control?.remediatestatus.toLowerCase().includes(search))
          return true;
        else return false;
      });
    }
    setRowSelection({});
    setTableRows(data);
  }, [searchParams, controls]);

  if (error) {
    return (
      <Typography
        component={"span"}
        variant="h6"
        color="text.primary"
        paragraph
      >
        loading...
      </Typography>
    );
  }

  return (
    <Container component="main" maxWidth="lg">
      <Heading heading={"Review and Fix Misconfigurations"} />
      <Box sx={{ width: "100%" }}>
        <Paper elevation={0} sx={{ mb: 2 }}>
          <Filter
            filter={{
              complianceStandard: searchParams.get("complianceStandard"),
              destructive: searchParams.get("destructive"),
              compliant: searchParams.get("compliant"),
            }}
            setFilter={updateFilter}
            table={table}
          ></Filter>
        </Paper>
        <TableContainer component={Paper} sx={{ p: 4 }}>
          <Paper elevation={0} sx={{ p: 0 }}>
            <Grid container flexDirection="row">
              <Paper
                key={"1"}
                component="div"
                sx={{
                  p: "0px 0px",
                  mb: 0,
                  display: "flex",
                  border: 0,
                  boxShadow: 0,
                }}
              >
                <Search
                  globalFilter={searchParams.get("search") || ""}
                  setGlobalFilter={updateSearch}
                ></Search>
              </Paper>
              <Paper
                key={"2"}
                component="div"
                elevation={0}
                sx={{
                  p: "2px 4px",
                  mb: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  flexGrow: 1,
                }}
              >
                <InputLabel id="page-size" sx={{ alignSelf: "center", p: 1 }}>
                  Show
                </InputLabel>
                <FormControl sx={{ m: 1, minWidth: 119 }} size="small">
                  <StyledSelect
                    labelId="page-size-select-label"
                    id="page-size-select"
                    value={table.getState().pagination.pageSize}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </StyledSelect>
                </FormControl>
              </Paper>
              <Paper
                key={"3"}
                component="div"
                elevation={0}
                sx={{
                  p: "2px 4px",
                  mb: 2,
                  display: "flex",
                  alignItems: "right",
                  width: 260,
                  justifyContent: "center",
                }}
              >
                <MultiRemediateSelect
                  selectedRows={rowSelection}
                  rows={table.getSelectedRowModel().flatRows}
                  resetRowSelection={table.resetRowSelection}
                  updateData={updateData}
                ></MultiRemediateSelect>
              </Paper>
            </Grid>
            <Grid container flexDirection="row">
              <Typography
                component={"span"}
                variant="h6"
                color="text.primary"
                fontWeight={400}
                fontSize={"16px"}
                sx={{
                  m: 1,
                  ml: 0,
                  mt: 0,
                  mb: 3,
                }}
              >
                {`Showing ${
                  table.getState().pagination.pageIndex *
                    table.getState().pagination.pageSize +
                  1
                }-${
                  (table.getState().pagination.pageIndex + 1) *
                    table.getState().pagination.pageSize <
                  tableRows.length
                    ? (table.getState().pagination.pageIndex + 1) *
                      table.getState().pagination.pageSize
                    : tableRows.length
                } of ${tableRows.length} entries`}
              </Typography>
            </Grid>
          </Paper>
          <Grid container flexDirection="row">
            <Table
              sx={{
                width: table.getCenterTotalSize(),
              }}
            >
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      let canSort = header.column.getCanSort();
                      return (
                        <StyledTableCell
                          key={header.id}
                          colSpan={header.colSpan}
                          sx={{
                            width: header.getSize(),
                            paddingRight: 0,
                          }}
                        >
                          {header.isPlaceholder ? null : (
                            <Box
                              onClick={
                                canSort
                                  ? header.column.getToggleSortingHandler()
                                  : undefined
                              }
                              sx={{
                                display: "flex",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <span>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </span>
                              {canSort
                                ? {
                                    asc: (
                                      <SvgIcon
                                        width="16"
                                        height="18"
                                        viewBox="0 0 16 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6 8V16.5"
                                          stroke="white"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M8 14.5L6 16.5L4 14.5"
                                          stroke="white"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M10 10V1.5"
                                          stroke="#FF6700"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M8 3.5L10 1.5L12 3.5"
                                          stroke="#FF6700"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </SvgIcon>
                                    ),
                                    desc: (
                                      <SvgIcon
                                        width="16"
                                        height="18"
                                        viewBox="0 0 16 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6 8V16.5"
                                          stroke="#FF6700"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M8 14.5L6 16.5L4 14.5"
                                          stroke="#FF6700"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M10 10V1.5"
                                          stroke="white"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M8 3.5L10 1.5L12 3.5"
                                          stroke="white"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </SvgIcon>
                                    ),
                                  }[header.column.getIsSorted() as string] ?? (
                                    <>
                                      <SvgIcon
                                        width="16"
                                        height="18"
                                        viewBox="0 0 16 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6 8V16.5"
                                          stroke="white"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M8 14.5L6 16.5L4 14.5"
                                          stroke="white"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M10 10V1.5"
                                          stroke="white"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M8 3.5L10 1.5L12 3.5"
                                          stroke="white"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </SvgIcon>
                                    </>
                                  )
                                : null}
                            </Box>
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <StyledTableCell colSpan={table.getTotalSize()}>
                      <Loader></Loader>
                    </StyledTableCell>
                  </TableRow>
                ) : (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <>
                        <TableRow key={row.id}>
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <StyledTableCell
                                key={cell.id}
                                sx={{
                                  width: cell.column.getSize(),
                                }}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </StyledTableCell>
                            );
                          })}
                        </TableRow>
                        {row.getIsExpanded() && (
                          <Findings
                            row={row}
                            arn={arn}
                            updateData={updateData}
                          ></Findings>
                        )}
                      </>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </Grid>
          <Grid
            container
            flexDirection="row"
            justifyContent={"flex-end"}
            sx={{ p: 0, pt: 3 }}
          >
            <Pagination
              count={table.getPageCount()}
              variant="outlined"
              sx={{
                color: "primary.main",
                borderColor: "primary.main",
              }}
              shape="rounded"
              page={table.getState().pagination.pageIndex + 1}
              onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                setPageIndex(value - 1);
              }}
            />
          </Grid>
        </TableContainer>
      </Box>
    </Container>
  );
};

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  try {
    const itemRank = rankItem(row.getValue(columnId), value);
    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  } catch (error) {
    return false;
  }
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

const severityFuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  if (rowA.original[columnId] === rowB.original[columnId]) {
    return 0;
  }

  return Severity[rowA.original[columnId]] > Severity[rowB.original[columnId]]
    ? 1
    : -1;
};

enum Severity {
  CRITICAL,
  HIGH,
  MEDIUM,
  LOW,
  UNKNOWN,
}

export default ListSecuirtyControls;
