import { FC, useState } from "react";
import QRCode from "qrcode";
import useSnackBars from "../../hooks/useSnackbar";
import { useCognitoEnableMfaMutation, useGetCognitoAccountQuery } from "../../generated";
import { Box, Button, Container, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import useNewrelic from "../../hooks/useNewrelic";

const SetupMFA: FC = () => {
    const { noticeError, log } = useNewrelic();
    const [loading, setLoading] = useState(false);
    const { setAlerts } = useSnackBars();
    const accessToken = localStorage.getItem("accessToken") || "";
    
    const formik = useFormik({
        initialValues: {
            mfaCode: "",
        },
        onSubmit: (values, {resetForm}) => {
            setLoading(true);
            setMfa();
            resetForm();
        },
    });

    const [qr, setQr] = useState("");
    
    const { data } = useGetCognitoAccountQuery({
        variables: {},
    });

    const [cognitoEnableMfaMutation] = useCognitoEnableMfaMutation();

    const generateToken = async () => {
        cognitoEnableMfaMutation({
        variables: {
            method: "associate",
            usercode: formik.values.mfaCode,
            username: data?.getCognitoAccount?.username,
            access_token: accessToken,
        },
        onCompleted: (cognitoData) => {
            const SecretCode = cognitoData?.cognitoEnableMfa?.SecretCode;
            // render QR code
            if (SecretCode) {
            const mfaSecretCode = `otpauth://totp/6pillars.ai?secret=${SecretCode}`;
            QRCode.toDataURL(mfaSecretCode, {
                width: 100,
                margin: 2,
            }).then((url: string) => {
                setQr(url);
            });
            }
        },
        });
    };

    const setMfa = () => {
        cognitoEnableMfaMutation({
            variables: {
                method: "set",
                usercode: formik.values.mfaCode,
                username: data?.getCognitoAccount?.username,
                access_token: accessToken,
            },
            onCompleted: (cognitoData) => {
                setLoading(false);
                if (cognitoData) {
                    const message = cognitoData?.cognitoEnableMfa?.message;
                    if (message === "Updated / Enabled MFA") {
                        log("Succsesfully set MFA!");
                        setAlerts([
                            {
                                severity: "success",
                                msg: "Succcessfully set MFA!",
                            },
                        ]);
                    } else {
                        noticeError(cognitoData?.cognitoEnableMfa?.error, "SetupMFA");
                        setAlerts([
                            {
                                severity: "error",
                                msg: cognitoData?.cognitoEnableMfa?.error,
                            },
                        ]);
                    }
                }
            },
        });
    };

    return (
        <Container maxWidth="lg" component={Paper}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    pb:2,
                    pt:2,
                    pl:2
                }}
            >
                <Typography
                    variant="h3"
                    fontSize={"22px"}
                    fontWeight={"500"}
                    color={"secondary"}
                    sx={{ pt: 0, pb: 4 }}
                >
                    Setup / Reset MFA
                </Typography>
                <Grid container flexDirection="row">
                    <Stack
                        spacing={3}
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                        <Button
                            variant="outlined"
                            onClick={generateToken}
                            sx={{
                            width: "198px",
                            fontSize: "16px",
                            textTransform: "none",
                            }}
                        >
                            Generate Token
                        </Button>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid item md={12}>
                                <TextField
                                    id="mfaCode"
                                    sx={{ width: "373.33px", height: "56px", pb: 2 }}
                                    label="MFA Code"
                                    value={formik.values.mfaCode}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Button
                                color="primary"
                                disabled={loading}
                                variant="contained"
                                type="submit"
                                sx={{
                                mt: 2,
                                mb: 2,
                                width: "198px",
                                backgroundColor: "secondary.main",
                                fontSize: "16px",
                                textTransform: "none",
                                }}
                            >
                                Update & Enable MFA
                            </Button>
                        </form>
                    </Stack>
                    <Paper
                        component="form"
                        elevation={0}
                        sx={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "flex-end",
                        }}
                    >
                        {qr !== "" ? <img src={qr} width="256" height="256" alt="MFA QR Code" /> : null}
                    </Paper>
                </Grid>
            </Box>
        </Container>
    );
};

export default SetupMFA;
