import { IconButton, TableRow } from "@mui/material";
import StyledTableCell from "../../common/StyledTableCell";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DeleteFramework from "./DeleteFramework";
import { useCallback } from "react";
import { MultiFramework } from "../../../generated";

interface ListMultiFrameworkItemProps {
	arn: string;
	row: MultiFramework;
	updateFramework: (
		frameworkName: string,
		standard: string,
		awsTechnicalReviewAccount: string,
		connectedAccount: string,
	) => void;
}

/**
 * Row information for list multiframeworks page
 * @param arn arn of user
 * @param row row from list multiframworks query
 * @param updateFramework calls updateFramework from Multiframework page
 * @returns row information of list multiframeworks page
 */
export default function ListMultiFrameworkItem({
	arn,
	row,
	updateFramework
}: ListMultiFrameworkItemProps){

	// Ensure that `row` has valid values by using fallback defaults for nullable fields
  const frameworkName = row.frameworkName ?? "No Compliance Workflows Name";
  const createdAt = row.createdAt ?? "No Date";
  const standard = row.standard ?? "No Standard";
  const awsTechnicalReviewAccount = row.awsTechnicalReviewAccount ?? "No Technical Review Account";
  const connectedAccounts = row.connectedAccounts ?? "No Connected Accounts";
	
	return (
	<TableRow>
		<StyledTableCell>{frameworkName}</StyledTableCell>
		<StyledTableCell>
			{new Date(createdAt).toLocaleString("en-GB")}
		</StyledTableCell>
		<StyledTableCell align="center">
			{standard}
		</StyledTableCell>
		<StyledTableCell align="center">
			{awsTechnicalReviewAccount.split(":")[4] ?? "N/A"}
		</StyledTableCell>
		<StyledTableCell align="center">
			{connectedAccounts}
		</StyledTableCell>
		<StyledTableCell align="center">
			<IconButton
				onClick={useCallback(() => {
					updateFramework(
						frameworkName,
						standard,
						awsTechnicalReviewAccount,
						connectedAccounts
					);	
				}, [updateFramework])}
				type="submit"
				color="secondary"
				>
				<AutorenewIcon />
			</IconButton>
		</StyledTableCell>
		<StyledTableCell align="center">
			<DeleteFramework
				arn={arn}
				frameworkName={frameworkName}
			/>
		</StyledTableCell>
	</TableRow>
	)	
}