import { createContext } from "react"
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import { Logging } from '@newrelic/browser-agent/features/logging'
import { JSErrors } from '@newrelic/browser-agent/features/jserrors';

const NEWRELIC_LICENSE_KEY= process.env.REACT_APP_NEWRELIC_LICENSE_KEY
const NEWRELIC_ACCOUNT_ID=process.env.REACT_APP_NEWRELIC_ACCOUNT_ID
const NEWRELIC_TRUST_KEY=process.env.REACT_APP_NEWRELIC_TRUST_KEY
const NEWRELIC_AGENT_ID=process.env.REACT_APP_NEWRELIC_AGENT_ID
const NEWRELIC_APPLICATION_ID=process.env.REACT_APP_NEWRELIC_APPLICATION_ID

const options = {
	init: {
		privacy:{cookies_enabled:true},
		ajax:{deny_list:["bam.nr-data.net"]}
	}, // NREUM.init
	info: {
		beacon:"bam.nr-data.net",
		errorBeacon:"bam.nr-data.net",
		licenseKey:NEWRELIC_LICENSE_KEY,
		applicationID:NEWRELIC_APPLICATION_ID,
		sa:1
	}, // NREUM.info
	loader_config: {
		accountID:NEWRELIC_ACCOUNT_ID,
		trustKey:NEWRELIC_TRUST_KEY,
		agentID:NEWRELIC_AGENT_ID,
		licenseKey:NEWRELIC_LICENSE_KEY,
		applicationID:NEWRELIC_APPLICATION_ID
	}, // NREUM.loader_config
	features: [
		Logging,
		JSErrors,
	],
}

interface NewrelicInfo {
	noticeError: (error: any, filename: string) => void;
	log: (msg: string) => void;
}

export const NewrelicContext = createContext<NewrelicInfo>({
	noticeError: (error: any, filename: string) => {},
	log: (msg: string) => {},
})

export function NewrelicProvider({ children }: { children: any }){
	// The agent loader code executes immediately on instantiation.
	const newrelic = new BrowserAgent(options);
	// new BrowserAgent(options);
	
	const noticeError = (error: any, filename: string) => {
		if (error instanceof Error) {
      newrelic.noticeError(error, { errorName: filename });
    } else {
      newrelic.noticeError(String(error), { errorName: filename });              
    }
	}

	const log = (msg: string) => {
		newrelic.log(msg);
	}

	return (
		<NewrelicContext.Provider 
			value={{
				noticeError: noticeError,
				log: log,
			}}
		>
			{children}
		</NewrelicContext.Provider>
	);
}