import LoadingButton from "@mui/lab/LoadingButton";
import { Box, CircularProgress, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import ExportTechnicalButton from "./ExportTechnicalControls";
import ConnectedAccount from "../../../models/ConnectedAccount";
import { useSubmitFindingsEmailMutation } from "../../../generated";
import useSnackBars from "../../../hooks/useSnackbar";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface GenerateReportsProps {
	arn: string;
  frameworkSelection: string;
  technicalControlsData: any;
	answers: Array<{
    id: string;
    description: string;
    selection: string;
  }>;
  connectedAccounts: Array<ConnectedAccount>;
	changeTab: (
    index: number,
  ) => void;
}

/**
 * Page to Generate Reports
 * @param arn arn of user
 * @param frameworkSelection standard selected
 * @param technicalControlsData all the table data from the technical contorls page
 * @param answers usestate that keeps track of answers selected in this page
 * @param connectedAccounts state of connectedAccounts information
 * @param changeTab tab to switch to after this page is completed
 * @returns 
 */
export default function GenerateReports({
	arn,
  frameworkSelection,
  technicalControlsData,
	answers,
  connectedAccounts,
	changeTab
}: GenerateReportsProps) {
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [emailSpinner, setEmailSpinner] = useState(false);
  const userEmail = JSON.parse(localStorage.getItem('user') || '{}')?.email || null;
  const { setAlerts } = useSnackBars();
  
  let frameworkKey = "";
  if(frameworkSelection === "ISO-IEC 27001:2022 Annex A"){
    frameworkKey = "ISO2022";
  } else {
    frameworkKey = "SOC2";
  }

  //Change to list frameworks tab
  const handleClick = useCallback(() => {
    changeTab(5);
  }, [changeTab])

  const [submitFindingsEmailMutation] = useSubmitFindingsEmailMutation({
    variables: {
      arn,// value for 'arn'
      connectedAccounts,// value for 'connectedAccounts'
      standard: frameworkSelection,// value for 'standard'
      compliancestatus: "",// value for 'compliancestatus'
      intrusive: "",// value for 'intrusive'
    },
    context: {
      apiName: "well_architected",
    },
  });

  //Triggers Detailed Findings submitFindingsEmailMutation API
  const submitOnClick = useCallback(() => {
    setEmailSpinner(true);
    submitFindingsEmailMutation().then(({ data }: any) => {
      if(data?.submitFindingsEmail?.status === "COMPLETE"){
        setAlerts([
          {
            severity: "success",
            msg: `A copy of detailed findings report has been emailed to ${userEmail}`,
          },
        ]);  
        setEmailSpinner(false);
      } else {
        setAlerts([
          {
            severity: "info",
            msg: `A copy of detailed findings report will be emailed shortly to ${userEmail}`,
          },
        ]);
        setEmailSpinner(false);
      }
    });
  }, [setEmailSpinner, submitFindingsEmailMutation, setAlerts, userEmail]);

  //Joins CSV lines
  const csvLines = (lines: any) => {
    return lines
      .map((e: any) => {
        return e.join(",");
      })
      .join("\r\n");
  };

  //Export CSV
  const exportCsv = useCallback(() => {
    setActiveSpinner(true);
    const header = ['ID', 'Description', 'Answer']
    const csvFile = new Blob(
      [
        `${
          csvLines([
            header,
          ])
        }
        \r\n
        ${
          csvLines(
            answers.map((question: any) => [
              question.id,
              question.description,
              question.selection,
            ])
          )
        }`,
      ],
      {
        type: "text/csv",
      }
    );
    const name = `Policy_and_Process_Summary_Report_${Date.now()}.csv`;
    const link = document.createElement("a");
    link.href = URL.createObjectURL(csvFile);

    link.setAttribute("visibility", "hidden");
    link.download = name;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setActiveSpinner(false);
  }, [setActiveSpinner, answers]); 

	return (
		<Container maxWidth="lg" component={Paper}>
			<Grid container sx={{ pb: 2, pt: 2 }} alignItems="center">
        <Grid item md={12} sx={{ pt: 2 }}>
          <Typography variant="h4" color="primary.main" gutterBottom>
						Generate Auditor Reports
          </Typography>
        </Grid>
				<Grid item md={4} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ pl: 2, fontWeight: "bold", ml: 3 }}>
						Generate Technical Controls Report
          </Typography>
        </Grid>
        <Grid item md={8} sx={{ mb: 2 }}>
          <Stack direction="row">
            <ExportTechnicalButton
						type={"CSV"}
						table={technicalControlsData}
						filter={{
              "complianceStandard":frameworkSelection,
              "compliant": "",
              "destructive": "",
            }}
						sx={{
								m: 0,
								ml: 1,
								mb: 1,
								width: "50%",
								fontWeight: 600,
						}}
					/>
					<ExportTechnicalButton
						type={"PDF"}
						table={technicalControlsData}
						filter={{
              "complianceStandard":frameworkSelection,
              "compliant": "",
              "destructive": "",
            }}
						sx={{
								m: 0,
								ml: 1,
								mb: 1,
								width: "50%",
								flexGrow: 0,
								fontWeight: 600,
						}}
					/>
          </Stack>
        </Grid>
				<Grid item md={4} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ pl: 2, fontWeight: "bold", ml: 3 }}>
						Email Technical Controls Detailed Findings Report
          </Typography>
        </Grid>
        <Grid item md={8} sx={{ mb: 2 }}>
          <Stack direction="row">
						<LoadingButton
              color="primary"
              variant="outlined"
              type="submit"
              disabled={emailSpinner}
              loading={emailSpinner}
              onClick={submitOnClick}
              sx={{
                m: 0,
								ml: 1,
								mb: 1,
								width: "50%",
								flexGrow: 0,
								fontWeight: 600,
              }}
              loadingIndicator={
                <CircularProgress color={"secondary"} size={22} />
              }
            >
              Email
            </LoadingButton>
          </Stack>
        </Grid>
				<Grid item md={4} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ pl: 2, fontWeight: "bold", ml: 3 }}>
						Generate Policy and Process Summary Report
          </Typography>
        </Grid>
        <Grid item md={8} sx={{ mb: 2 }}>
          <Stack direction="row">
						<LoadingButton
              color="primary"
              variant="outlined"
              type="submit"
              disabled={activeSpinner}
              loading={activeSpinner}
              onClick={exportCsv}
              sx={{
                m: 0,
								ml: 1,
								mb: 1,
								width: "50%",
								flexGrow: 0,
								fontWeight: 600,
              }}
              loadingIndicator={
                <CircularProgress color={"secondary"} size={22} />
              } 
            >
              Download
            </LoadingButton>
          </Stack>
        </Grid>
        <Grid item md={4} sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ pl: 2, fontWeight: "bold", ml: 3 }}>
            Open Continuous Compliance
          </Typography>
        </Grid>
        <Grid item md={8} sx={{ mb: 2 }}>
          <Stack direction="row">
						<LoadingButton
              color="primary"
              variant="outlined"
              type="submit"
              endIcon={<OpenInNewIcon />}
              href={`/rules?cs=${frameworkKey}`}
              target={"_blank"}
              sx={{
                m: 0,
								ml: 1,
								mb: 1,
								width: "50%",
								flexGrow: 0,
								fontWeight: 600,
              }}
            >
              Open
            </LoadingButton>
          </Stack>
        </Grid>
        <Grid item md={10} />
        <Grid item md={2}>
          <Box>
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              disabled={activeSpinner || emailSpinner}
              onClick={handleClick}
              sx={{
                width: 157,
                height: 40,
                bgcolor: "secondary.main",
                "&:hover": {
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                },
              }}
            >
              Next
            </LoadingButton>
          </Box>
        </Grid>
			</Grid>
		</Container>		
	)
}