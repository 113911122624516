import Standard from "../../models/Standard";

interface Dictionary<T> {
  [Key: string]: T;
}

const changeLocation = (location: string) => {
  let finalLocation: string;
  const locationDictionary: Dictionary<string> = {
    rules: "/rules",
    failures: "/rules?compliant=FAILED",
    compliant: "/rules?compliant=PASSED",
    cis120: `/rules?complianceStandard=${Standard.CIS120}`,
    cis140: `/rules?complianceStandard=${Standard.CIS140}`,
    pci: `/rules?complianceStandard=${Standard.PCIDSS}`,
    soc2: `/rules?complianceStandard=${Standard.SOC2}`,
    cdr: `/rules?complianceStandard=${Standard.CDR}`,
    iso: `/rules?complianceStandard=${Standard.ISO}`,
    iso2022: `/rules?complianceStandard=${Standard.ISO2022}`,
    afsbp: `/rules?complianceStandard=${Standard.AFSBP}`,
    nist: `/rules?complianceStandard=${Standard.NIST}`,
    nistcsf: `/rules?complianceStandard=${Standard.NISTCSF}`,
    wat: "/wat",
    so: `/rules?complianceStandard=${Standard.S0}`,
    slza: `/rules?complianceStandard=${Standard.SLZA}`,
    e8: `/rules?complianceStandard=${Standard.E8}`,
    nzism: `/rules?complianceStandard=${Standard.NZISM}`,
    watf: `/rules?complianceStandard=${Standard.WAT}`,
    genai: `/rules?complianceStandard=${Standard.GENAI}`,
    cccc: `/rules?complianceStandard=${Standard.CCCC}`,
    fedramp: `/rules?complianceStandard=${Standard.FEDRAMP}`,
    pci40: `/rules?complianceStandard=${Standard.PCIDSS40}`,
    controltower: `/rules?complianceStandard=${Standard.CT}`,
    glba: `/rules?complianceStandard=${Standard.GLBA}`,
    acsc: `/rules?complianceStandard=${Standard.ACSC}`,
    gxpeu: `/rules?complianceStandard=${Standard.GXPEU}`,
    gxp21: `/rules?complianceStandard=${Standard.GXP}`,
    cis8: `/rules?complianceStandard=${Standard.CIS8}`,
    nistsp: `/rules?complianceStandard=${Standard.NISTSP}`,
    hippa: `/rules?complianceStandard=${Standard.HIPAA2013}`,
    cis71: `/rules?complianceStandard=${Standard.CIS71}`,
    cis130: `/rules?complianceStandard=${Standard.CIS130}`,
  };
  finalLocation = locationDictionary[location]
    ? locationDictionary[location]
    : "/rules";
  window.location.href = finalLocation;
};

export { changeLocation };
