import { useGetTemplateEngineListTemplatesLazyQuery } from "../generated";
import { useEffect, useState } from "react";
import useNewrelic from "./useNewrelic";

const useGetScannnerResults = () => {
  const [apiPage, setApiPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [summaryItems, setSummaryItems]: any[] = useState([]);
  const [listTemplates] = useGetTemplateEngineListTemplatesLazyQuery();
  const { noticeError } = useNewrelic();
  
  useEffect(() => {
    setApiPage(1);
  }, []);

  useEffect(() => {
    if (apiPage > 0) {
      getTemplates();
    }
  }, [apiPage]);

  const getTemplates = async () => {
    try {
      const templateData = await listTemplates({
        context: { apiName: "template_scanner" },
        variables: { page: apiPage, limit: 200 },
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
      });
      const resultsJsonString =
        templateData?.data?.getTemplateEngineListTemplates?.results;
      if (resultsJsonString === "{}" || !resultsJsonString) {
        setLoading(false);
      } else {
        const resultsJson = JSON.parse(resultsJsonString);
        const nextPage =
          templateData?.data?.getTemplateEngineListTemplates?.page;
        const summaryList: any[] = [];
        for (const summaryId in resultsJson) {
          const summaryDict = resultsJson[summaryId].summary;
          const recordCount = resultsJson[summaryId].results.length;
          summaryDict["recordCount"] = recordCount;
          summaryList.push(summaryDict);
        }
        setSummaryItems([...summaryItems, ...summaryList]);
        if (nextPage) {
          setApiPage(nextPage);
        } else {
          setLoading(false);
          setApiPage(0);
        }
      }
    } catch (error) {
      noticeError(error, "useGetScannerResults");
      setLoading(false);
    }
  };

  const refetch = () => {
    setSummaryItems([]);
    setLoading(true);
    setApiPage(1);
  };

  return { loading, summaryItems, refetch };
};

export default useGetScannnerResults;
