import { FC, useState } from "react";
import { useFormik } from "formik";
import {
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  LinearProgress,
  Alert,
  InputAdornment,
  IconButton,
  Container,
  Paper,
} from "@mui/material";
import * as yup from "yup";
import useSnackBars from "../../hooks/useSnackbar";
import { useCognitoChangeUserPasswordMutation } from "../../generated";
import useAccount from "../../hooks/useAccount";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const validationSchema = yup.object({
    currentPassword: yup
        .string()
        .required("Current Password is required"),
    newPassword: yup
        .string()
        .min(8, "Password should be of minimum 8 characters length")
        .required("New Password is required"),
    confirmPassword: yup
        .string()
        .required("Confirm Password is required")
        .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

const ResetPassword: FC = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ status: false, msg: "" });
    
    //const for current password eye toggle
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const handleClickShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
    const handleMouseDownCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
    //const for new password eye toggle
    const [showNewPassword, setShowNewPassword] = useState(false);
    const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);
    //const for new confirm password eye toggle
    const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);
    const handleClickShowNewConfirmPassword = () => setShowNewConfirmPassword(!showNewConfirmPassword);
    const handleMouseDownNewConfirmPassword = () => setShowNewConfirmPassword(!showNewConfirmPassword);

    const { setAlerts } = useSnackBars();
    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, {resetForm}) => {
            setLoading(true);
            setError({ status: false, msg: "" });
            changePassword();
            resetForm();
        },
    });

    const { arn } = useAccount();
    const accessToken = localStorage.getItem("accessToken") || "";
    
    const [cognitoChangeUserPasswordMutation] = useCognitoChangeUserPasswordMutation({
        variables: {
        arn: arn,
        existing: formik.values.currentPassword,
        newpwone: formik.values.newPassword,
        access_token: accessToken,
        },
    });

    const changePassword = () => {
        cognitoChangeUserPasswordMutation().then(({ data }: any) => {
            setLoading(false);
            if(data.cognitoChangeUserPassword.status === 1){
                setAlerts([
                {
                    severity: "success",
                    msg: "Password successfully changed",
                },
                ]);
            } else {
                setAlerts([
                {
                    severity: "error",
                    msg: "Error changing password.",
                },
                ]);
            }
        })
        .catch((e) => {
            setAlerts([
                {
                    severity: "error",
                    msg: "Error changing password - " + e,
                },
            ]);
        });  
    };

    return (
    <>
        <form onSubmit={formik.handleSubmit}>
            <Container maxWidth="lg" component={Paper}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    pb:2,
                    pt:2,
                    pl:2
                }}
            >
            <Typography
                variant="h3"
                fontSize={"22px"}
                fontWeight={"500"}
                color={"secondary"}
                sx={{ pt: 0, pb: 4 }}
            >
                Change password
            </Typography>
            <TextField
                fullWidth
                id="currentPassword"
                name="currentPassword"
                label="Current Password"
                type={showCurrentPassword ? "text" : "password"}
                sx={{ maxWidth: "350px", pb: 2 }}
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                InputProps={{ // <-- This is where the toggle button is added.
                    endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCurrentPassword}
                            onMouseDown={handleMouseDownCurrentPassword}
                        >
                            {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        </InputAdornment>
                    )
                }}
                error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                helperText={formik.touched.currentPassword && formik.errors.currentPassword}
            />
            <TextField
                fullWidth
                id="newPassword"
                name="newPassword"
                label="Create New Password"
                type={showNewPassword ? "text" : "password"}
                sx={{ maxWidth: "350px", pb: 2 }}
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                InputProps={{ // <-- This is where the toggle button is added.
                    endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownNewPassword}
                        >
                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        </InputAdornment>
                    )
                }}
                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                helperText={formik.touched.newPassword && formik.errors.newPassword}
            />
            <TextField
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                type={showNewConfirmPassword ? "text" : "password"}
                sx={{ maxWidth: "350px", pb: 2 }}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                InputProps={{ // <-- This is where the toggle button is added.
                    endAdornment: (
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewConfirmPassword}
                            onMouseDown={handleMouseDownNewConfirmPassword}
                        >
                            {showNewConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        </InputAdornment>
                    )
                }}
                error={
                formik.touched.newPassword && Boolean(formik.errors.confirmPassword)
                }
                helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                }
            />
            <Button
                color="primary"
                disabled={loading}
                variant="contained"
                type="submit"
                sx={{
                mb: 2,
                width: "198px",
                backgroundColor: "secondary.main",
                fontSize: "16px",
                textTransform: "none",
                }}
            >
                Update
            </Button>
            {error.status && <Alert severity="error">{error.msg}</Alert>}
            <Divider />
            {loading && <LinearProgress color="secondary" />}
            </Box>
            </Container>
        </form>
    </>
    );
};

export default ResetPassword;
