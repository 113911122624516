import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useCallback, useState } from "react";
import {
  GetUrlDocument,
} from "../../../generated";
import useSnackBars from "../../../hooks/useSnackbar";
import { IconButton, Switch } from "@mui/material";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { useLazyQuery } from "@apollo/react-hooks";

interface FrameworkQuestionProps {
  index: number;
  row: {
    question_id: string;
    question: string;
    answer_id: string;
    answers: string[];
    template: string;
    questionnaire_answer: string;
  };
  answer: string; // Current answer for this question
  onAnswerChange: (questionId: string, question: string, answer: string) => void; // Function to handle answer changes
}

/**
 * Display Framework Question
 * @param index index value
 * @param row row data
 * @param answer selected answer yes or no
 * @param onAnswerChange handle to be called when answer switch is toggled
 * @returns 
 */
export default function FrameworkQuestion({
  index,
  row,
  answer,
  onAnswerChange,
}: FrameworkQuestionProps) {
  const { setAlerts } = useSnackBars();
  const [disabledIcon, setDisabledIcon] = useState(false);
  const [processDocName,setProcessDocName] = useState("");
  
  //Downloads Process Document
  const onDownloadClick = (url:string, filename:string) => {
    const element = document.createElement("a");
    element.href = url;
    element.download = filename;
    element.target = "_blank";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    setDisabledIcon(false);
  }

  const [ getRoleTemplateURL ] = useLazyQuery(GetUrlDocument,{
    onCompleted: (RoleTemplateURLData) => {
      onDownloadClick(RoleTemplateURLData?.getTemplateS3URL, processDocName);
    },
    onError: () => {
      setDisabledIcon(false);
      setAlerts([
        {
          severity: "error",
          msg: "Failed to fetch the template. Please try again.",
        }
      ]);
    },
  });

  //Sets the correct file to download and calls the getRoleTemplateURL api
  const download = useCallback((question_id: string, question: string, template: string) => {
    const basePath = "process-documents/";
    setDisabledIcon(true);
    setProcessDocName(template);
    getRoleTemplateURL({
      variables: { template: `${basePath}${template}` },
    });

    // Toggle the switch for the current row
    const newValue = answer[0] === "yes" ? "no" : "yes";
    if (newValue !== "no"){
      onAnswerChange(question_id, question, newValue);
    }
  }, [setDisabledIcon, setProcessDocName, getRoleTemplateURL, onAnswerChange, answer]);
  
  //Handles the switch answer toggling from yes to no
  const handleSwitchToggle = useCallback(() => {
    const newAnswer = answer === "yes" ? "no" : "yes";
    onAnswerChange(row.question_id, row.question, newAnswer);
  }, [onAnswerChange, answer, row.question_id, row.question]);

  return (
    <TableRow
      key={`${row.question_id}-${answer}`}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {index + 1}
      </TableCell>
      <TableCell component="th" scope="row">
        {row.question}
      </TableCell>
        <TableCell component="th" scope="row" align="center">
        <Switch
          color="success"
          checked={answer === "yes"}
          onChange={handleSwitchToggle}
          inputProps={{ "aria-label": `Toggle answer for question ${index + 1}` }}
        />
        </TableCell>
      <TableCell component="th" scope="row" align="center">
        <IconButton 
          onClick={
            useCallback(() => download(row.question_id, row.question, row.template), [download])} 
          disabled={disabledIcon} 
          type="submit" 
          color="secondary"
        >
          <CloudDownloadOutlinedIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
