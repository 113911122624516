import {
  Box,
  Collapse,
  IconButton,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useGetControlQuery } from "../../../generated";
import ConnectedAccount from "../../../models/ConnectedAccount";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ComplianceStatus from "../../common/ComplianceStatus";
import Loader from "../../common/Loader";

interface NonDisruptiveQuestionsProps {
  technicalReviewArn: string;
  index: number;
  accounttype: string;
  rows: any;
  row: any;
  rowSelection: any;
  setRowSelection: any;
  selectedControls: any[]; // Array of controls currently selected in the parent component
  updateSelectedControls: (control: any, isSelected: boolean) => void; // Function to update the selected controls state
  failedRows: string[];
  setFailedRows: React.Dispatch<React.SetStateAction<string[]>>;
  switchBehavior: any;
  connectedAccounts: Array<ConnectedAccount>;
  activeSpinner: boolean;
}

/**
 * Display Row Information of NonDisruptive Question and any findings for the row
 * @returns non disrutpive question row info
 */
export default function NonDisruptiveQuestions({
  technicalReviewArn,
  index,
  accounttype,
  rows,
  row,
  rowSelection,
  setRowSelection,
  selectedControls,
  updateSelectedControls,
  failedRows,
  setFailedRows,
  switchBehavior,
  connectedAccounts,
  activeSpinner,
}: NonDisruptiveQuestionsProps) {
  const [open, setOpen] = useState(false);
  let failedFinding = false;

  //Query to get the sec hub findings
  const { data, loading } = useGetControlQuery({
    variables: {
      arn: technicalReviewArn,
      controlname: row.controlname,
      connectedAccounts,
    },
  });

  //MAR - Multi Automated Remediation
  //RowSelection displays all the rows that have been selected in the MAR Modal
  //RowNames keeps track of which rows were selected and passes it to the updateBestPracticeMutation
  //SetFailedRows adds controls that have a failed finding to the array and passes it to the MAR Modal
  const handleQuestionAnswer = useCallback(
    (row: any) => {
      if (rowSelection.includes(row)) {
        setRowSelection(
          rowSelection.filter((r: any) => r.controlname !== row.controlname)
        );
        if (failedFinding) {
          setFailedRows(failedRows.filter((n: any) => n !== row.controlname));
        }
        updateSelectedControls(row, false); // Remove from parent state
      } else {
        setRowSelection([...rowSelection, row]);
        if (failedFinding) {
          setFailedRows([...failedRows, row.controlname]);
        }
        updateSelectedControls(row, true); // Add to parent state
      }
    },
    [
      rowSelection,
      setRowSelection,
      failedFinding,
      setFailedRows,
      updateSelectedControls,
      failedRows,
    ]
  );

  //This useEffect triggers if Select All Recommendations? is selected
  useEffect(() => {
    if (switchBehavior === "All") {
      setRowSelection(rows);
      rows.forEach((row: any) => updateSelectedControls(row, true));
      if (failedFinding) {
        setFailedRows((i) => [...i, row.controlname]);
      }
    } else {
      setRowSelection([]);
      rows.forEach((row: any) => {
        const isPreSelected = row?.original?.remediatestatus === "ENABLED";
        updateSelectedControls(row, isPreSelected);
      });
      if (failedFinding) {
        setFailedRows([]);
      }
    }
  }, [switchBehavior]);

  return (
    <>
      <TableRow
        key={row.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {index + 1}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.controlname}
        </TableCell>
        <TableCell component="th" scope="row" width={"75%"}>
          {row.original.controldescription}
        </TableCell>
        {loading ? (
          <TableCell align="center" width={"100%"}>
            <Loader />
          </TableCell>
        ) : (
          <TableCell align="center" width={"100%"}>
            {accounttype === "AUTOMATED" ? (
              <Switch
                checked={
                  selectedControls.some(
                    (control) => control.controlname === row.controlname
                  ) || row?.original?.remediatestatus === "ENABLED"
                } // Set checked state based on switchBehavior
                disabled={
                  row?.original?.remediatestatus === "ENABLED" || activeSpinner
                }
                onClick={() => {
                  handleQuestionAnswer(row);
                }}
                color="success"
              />
            ) : (
              <Typography>N/A - Read Only</Typography>
            )}
          </TableCell>
        )}
        <TableCell width={"5%"}>
          <IconButton color="secondary" onClick={() => setOpen(!open)}>
            {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {loading ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Loader />
            </Collapse>
          </TableCell>
        </TableRow>
      ) : (
        data?.getControl?.findings?.map((finding: any) => {
          if (finding.Compliance.Status === "FAILED") {
            failedFinding = true;
          }
          return (
            <TableRow
              key={finding?.Id?.split("/").reverse()[0]}
              sx={{ "& > *": { borderBottom: "unset" } }}
            >
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={2}
              >
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      borderBottom: "set",
                    }}
                  >
                    <ComplianceStatus value={finding.Compliance.Status} />
                  </Box>
                </Collapse>
              </TableCell>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={10}
              >
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box
                    sx={{ margin: 1, display: "block", borderBottom: "set" }}
                  >
                    {finding?.Resources &&
                      finding?.Resources[0]?.Name !== "N/A" && (
                        <Typography variant="body1" align="left" gutterBottom>
                          Name:{" "}
                          {finding?.Resources
                            ? finding?.Resources[0]?.Name
                            : ""}
                        </Typography>
                      )}
                    <Typography variant="body1" align="left" gutterBottom>
                      Service ID:{" "}
                      {finding?.Resources ? finding?.Resources[0]?.Id : ""}
                    </Typography>
                    <Typography variant="body1" align="left" gutterBottom>
                      Finding ID: {finding?.Id?.split("/").reverse()[0]}
                    </Typography>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          );
        })
      )}
    </>
  );
}
