import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { NewrelicProvider } from "./providers/NewrelicProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <NewrelicProvider>
      <App />
    </NewrelicProvider>
  </React.StrictMode>
);
