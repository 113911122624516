import { createContext, useState, useEffect } from "react";
import { useGetUserAccountsQuery } from "../generated";
import { useLocation, useNavigate } from "react-router-dom";
import useSnackBars from "../hooks/useSnackbar";
import ConnectedAccount from "../models/ConnectedAccount";
import useNewrelic from "../hooks/useNewrelic";
//import { authProtectedRoutes } from "../routes/routes";

interface Account {
  arn: string;
  defaultregion: string;
  accounttype: string;
  externalId: string;
  accessnickname: string;
  member_accounts: Array<string>;
  member_regions: Array<string>;
  connectedAccounts: Array<ConnectedAccount>;
  accountIdRef: string;
}
interface AccountInfo {
  arn: string;
  defaultregion: string;
  accounttype: string;
  externalId: string;
  accessnickname: string;
  featureAllowed: (feature: string) => boolean;
  accounts: Account[];
  setAccount: (account: any) => void;
  setAccounts: (accounts: any) => void;
  loading: boolean;
  setLoading: (loading: any) => void;
  refreshAccounts: (data: any) => void;
  member_accounts: Array<string>;
  member_regions: Array<string>;
  connectedAccounts: Array<ConnectedAccount>;
  setAccountIdRef: (data: string) => void;
  accountIdRef: string;
  getFilteredConnectedAccounts: () => Array<ConnectedAccount>;
}

export const AccountContext = createContext<AccountInfo>({
  arn: "",
  defaultregion: "",
  accounttype: "",
  externalId: "",
  accessnickname: "",
  featureAllowed: (feature: string) => false,
  accounts: [],
  setAccount: (account: any) => {},
  setAccounts: (accounts: any) => {},
  loading: true,
  setLoading: (loading: any) => {},
  refreshAccounts: (data: any) => {},
  member_accounts: [],
  member_regions: [],
  connectedAccounts: [],
  accountIdRef: "organisation",
  setAccountIdRef: (data: string) => {},
  getFilteredConnectedAccounts: () => [],
});

export function AccountProvider({ children }: { children: any }) {
  const navigate = useNavigate();
  const { noticeError } = useNewrelic();
  const { setAlerts } = useSnackBars();
  const [account, setAccount] = useState<Account>({
    arn: "",
    defaultregion: "",
    externalId: "",
    accounttype: "",
    accessnickname: "",
    member_accounts: [],
    member_regions: [],
    connectedAccounts: [],
    accountIdRef: "organisation",
  });
  const [accountIdRef, setAccountIdRef] = useState<string>("organisation");
  const [region, setRegion] = useState<string>("all");
  const [accounts, setAccounts] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const { data, refetch, loading, error } = useGetUserAccountsQuery();
  const location = useLocation();
  const handleAwsAccountChange = (account: any) => {
    localStorage.setItem("userSelectecdAwsAccount", JSON.stringify(account));
    setAccount(account);
  };

  const refreshAccounts = (data: any) => {
    refetch();
  };

  const featureAllowed = (feature: string) => {
    if (account?.accounttype === "AUTOMATED") {
      return true;
    }
    return false;
  };

  const getFilteredConnectedAccounts = () => {
    if (account?.connectedAccounts) {
      return account.connectedAccounts
        .filter((connectedAccount: ConnectedAccount) => {
          return connectedAccount.accountIdRef === accountIdRef;
        })
        .map((connectedAccount: ConnectedAccount) => {
          return {
            accountIdRef: connectedAccount.accountIdRef,
            region: connectedAccount.region,
          };
        });
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!loading) {
      let items: any = data?.getUserAccounts?.items;
      if (items !== undefined && items?.length > 0) {
        const cachedAccountStr = localStorage.getItem(
          "userSelectecdAwsAccount"
        );
        if (cachedAccountStr !== null) {
          const cachedAccount = JSON.parse(cachedAccountStr);

          if (
            cachedAccount &&
            cachedAccount?.arn &&
            items.some((i: any) => i.arn === cachedAccount.arn)
          ) {
            let connectedAccounts: ConnectedAccount[] = [];
            if (cachedAccount.connectedAccounts) {
              connectedAccounts = cachedAccount.connectedAccounts;
            }
            let accountIdRef = "organisation";
            if (cachedAccount?.accountIdRef) {
              accountIdRef = cachedAccount.accountIdRef;
            }

            setAccount({
              arn: cachedAccount.arn,
              defaultregion: cachedAccount.defaultregion,
              accounttype: cachedAccount.accounttype,
              externalId: cachedAccount.externalid,
              accessnickname: cachedAccount.accessnickname,
              member_accounts: cachedAccount.member_accounts,
              member_regions: cachedAccount.member_regions,
              connectedAccounts: connectedAccounts,
              accountIdRef: accountIdRef,
            });
          } else {
            setAccount({
              arn: items[0].arn,
              defaultregion: items[0].defaultregion,
              accounttype: items[0].accounttype,
              externalId: items[0].externalid,
              accessnickname: items[0].accessnickname,
              member_accounts: items[0].member_accounts,
              member_regions: items[0].member_regions,
              connectedAccounts: items[0].connectedAccounts,
              accountIdRef: "organisation",
            });
          }
        } else {
          setAccount({
            arn: items[0].arn,
            defaultregion: items[0].defaultregion,
            accounttype: items[0].accounttype,
            externalId: items[0].externalid,
            accessnickname: items[0].accessnickname,
            member_accounts: items[0].member_accounts,
            member_regions: items[0].member_regions,
            connectedAccounts: items[0].connectedAccounts,
            accountIdRef: "organisation",
          });
        }
        setAccounts(items);
        setDataLoading(false);
      } else if (error) {
        noticeError(error, "AccountProvider");
        setAccounts([]);
        setDataLoading(false);
        setAlerts([
          {
            severity: "error",
            msg: `No Accounts`,
          },
        ]);
      } else {
        setDataLoading(false);
        const paths = ["/template-scanner", "/template-scanner-results"];
        if (!paths.includes(location.pathname)) {
          //navigate("/accounts");
        }
      }
    }
  }, [data, loading]);

  return (
    <AccountContext.Provider
      value={{
        ...account,
        setAccount: handleAwsAccountChange,
        accounts: accounts,
        featureAllowed: featureAllowed,
        setAccounts,
        loading: dataLoading,
        setLoading: setDataLoading,
        refreshAccounts: refreshAccounts,
        connectedAccounts: account.connectedAccounts,
        setAccountIdRef: setAccountIdRef,
        accountIdRef: accountIdRef,
        getFilteredConnectedAccounts: getFilteredConnectedAccounts,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}
