import { useGetControlsStatusLazyQuery } from "../generated";
import { useState } from "react";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

interface ControlStatuesCSVQueryInput {
  arn: string;
}

const useContolStatuesCSV = ({ arn }: ControlStatuesCSVQueryInput) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<String>();
  const [getControlStatus] = useGetControlsStatusLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const csvLines = (lines: any) => {
    return lines
      .map((e: any) => {
        return e.join(",");
      })
      .join("\r\n");
  };

  const exportPdf = (name: string, controls: any) => {
    const doc = new jsPDF("p", "mm", "a4");
    autoTable(doc, {
      head: [
        [
          "Control Name",
          "New Control Name",
          "Auto Remediate Status",
          "Control Alert Status",
        ],
      ],
      body: controls.map((control: any) => [
        control["controlname"],
        control["newControlName"],
        control["remediatestatus"],
        control["alertstatus"],
      ]),
    });

    doc.save(name);
  };

  const exportCsv = (name: string, controls: any) => {
    let csvFile = new Blob(
      [
        csvLines([
          [
            "Control Name",
            "New Control Name",
            "Auto Remediate Status",
            "Control Alert Status",
          ],
        ]) +
          "\r\n" +
          csvLines(
            controls.map((control: any) => [
              control["controlname"],
              control["newControlName"],
              control["remediatestatus"],
              control["alertstatus"],
            ])
          ),
      ],
      {
        type: "text/csv",
      }
    );
    let link = document.createElement("a");
    link.href = URL.createObjectURL(csvFile);

    link.setAttribute("visibility", "hidden");
    link.download = name;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const initiateDownload = async () => {
    setLoading(true);
    try {
      let result = await getControlStatus({
        variables: { arn, page: 1, limit: 1000 },
        notifyOnNetworkStatusChange: true,
      });
      const account_ref = arn.split(":")[4];
      const name = `Control_Status_${account_ref}_${Date.now()}.csv`;
      exportCsv(name, result?.data?.getControlsStatus);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Download Failed");
    }
  };
  return { loading, error, initiateDownload };
};

export default useContolStatuesCSV;
