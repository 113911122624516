import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Grid,
  Typography,
  LinearProgress,
  Select,
  MenuItem,
  Alert,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Control from "../../models/Control";
import { Row } from "@tanstack/react-table";
import { useInitiateRemediationsMutation } from "../../generated";
import useAccount from "../../hooks/useAccount";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import SixPillarsRoleButton from "./SixPillarsRoleButton";
import useNewrelic from "../../hooks/useNewrelic";

const RemediateFailedFindingsDialog: FC<any> = ({
  open,
  handleClose,
  handleConfirm,
  row,
  accountRefIds,
  findings,
}: {
  open: boolean;
  handleClose: any;
  handleConfirm: (status: boolean) => void;
  row: Row<Control>;
  accountRefIds: Array<string>;
  findings: Array<any>;
}) => {
  const {
    arn,
    member_accounts,
    member_regions,
    accounttype,
    defaultregion,
    externalId,
  } = useAccount();
  const { noticeError } = useNewrelic();
  const [accountRefId, setAccountRefId] = useState("select");
  const [error, setError] = useState({ status: false, msg: "" });
  const [initiateRemediations, { loading }] = useInitiateRemediationsMutation();
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "serviceId",
      headerName: "Resource Name",
      width: 400,
    },
    { field: "arn", headerName: "ARN", width: 600 },
  ];

  useEffect(() => {
    if (accountRefIds.length === 1) {
      setAccountRefId(accountRefIds[0]);
    }
  });

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"xl"}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          Remediate all failed findings in your AWS account
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ m: 1 }}>
            <Grid item md={2}>
              <Typography fontSize={"14px"}>Control ID :</Typography>
            </Grid>
            <Grid item md={10}>
              <Typography fontSize={"14px"}>
                {row.original.controlname}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ m: 1 }}>
            <Grid item md={2}>
              <Typography fontSize={"14px"}>Control Description:</Typography>
            </Grid>
            <Grid item md={10}>
              <Typography fontSize={"14px"}>
                {row.original.controldescription}
              </Typography>
            </Grid>
          </Grid>
          {(() => {
            const remediationSteps = row.original.remediationsteps;

            if (!remediationSteps) {
              return null;
            }

            const parsedData = JSON.parse(remediationSteps);
            const hasDescription =
              parsedData.description &&
              typeof parsedData.description === "string" &&
              parsedData.description.trim() !== "";
            const hasDefaultValues =
              parsedData.defaultValues &&
              typeof parsedData.defaultValues === "object" &&
              Object.keys(parsedData.defaultValues).length > 0;

            if (!hasDescription && !hasDefaultValues) {
              return null;
            }

            return (
              <>
                {hasDescription && (
                  <Grid container sx={{ m: 1 }}>
                    <Grid item md={2}>
                      <Typography fontSize={"14px"}>
                        Remediation Description:
                      </Typography>
                    </Grid>
                    <Grid item md={10}>
                      <Typography
                        fontSize={"14px"}
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {parsedData.description}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {hasDefaultValues && (
                  <Grid container sx={{ m: 1 }}>
                    <Grid item md={2}>
                      <Typography fontSize={"14px"}>
                        Remediation Default Values:
                      </Typography>
                    </Grid>
                    <Grid item md={10}>
                      <Typography
                        fontSize={"14px"}
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {Object.entries(parsedData.defaultValues).map(
                          ([key, value]) => {
                            return (
                              <React.Fragment>
                                <b>{`${key}`}</b>: {`${value}`}
                                <br />
                              </React.Fragment>
                            );
                          }
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </>
            );
          })()}
          <Grid container sx={{ m: 1 }}>
            <Grid item md={4}>
              <Typography fontSize={"14px"}>Select AWS Account ID :</Typography>
            </Grid>
            <Grid item md={8}>
              <Select
                labelId="select-by-accountId"
                id="select-by-accountId"
                value={accountRefId}
                label="Select AWS Account ID"
                size="small"
                onChange={(e) => {
                  setAccountRefId(e.target.value as string);
                }}
                sx={{ ml: 2 }}
              >
                <MenuItem disabled={true} value={"select"}>
                  Select AWS Account ID
                </MenuItem>
                {accountRefIds
                  .filter(
                    (id) =>
                      member_accounts.includes(id) || id === arn.split(":")[4]
                  )
                  .map((id: string) => (
                    <MenuItem value={id}>{id}</MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container sx={{ m: 1 }}>
            <Typography fontSize={"14px"}>
              You are about to remediate following findings in your AWS account
              {accountRefId === "select" ? "" : ` (${accountRefId})`}
            </Typography>
          </Grid>
          {accountRefId === "select" && (
            <Grid container sx={{ m: 1 }}>
              <Typography
                fontSize={"14px"}
                sx={{ color: "red", textAlign: "center" }}
              >
                Please select an AWS Account ID to display the failed findings
              </Typography>
            </Grid>
          )}
          {accountRefId != "select" && (
            <>
              <DataGrid
                rows={findings
                  .filter((finding) => {
                    const region = finding?.Resources
                      ? finding?.Resources[0]?.Id.split(":")[3]
                      : "";
                    return (
                      finding.AwsAccountId === accountRefId &&
                      (defaultregion === region ||
                        region === "" ||
                        member_regions.includes(region)) &&
                      finding.Compliance.Status === "FAILED"
                    );
                  })
                  .map((finding: any, index) => {
                    let serviceId = finding?.Resources[0]?.Name;
                    if (serviceId === "N/A") {
                      serviceId = finding?.Resources[0]?.Id.split("/")[1];
                    }
                    if (!serviceId || serviceId === "") {
                      serviceId = finding?.Resources[0]?.Id;
                    }
                    return {
                      id: index + 1,
                      serviceId: serviceId,
                      arn: finding?.Resources ? finding?.Resources[0]?.Id : "",
                    };
                  })}
                density="compact"
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5]}
                //checkboxSelection
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={loading || accountRefId === "select"}
            onClick={() => {
              setError({
                status: false,
                msg: "",
              });
              initiateRemediations({
                context: {
                  apiName: "automation_approval",
                },
                variables: {
                  arn: arn,
                  accountRef: accountRefId,
                  control: row.original.controlname,
                },
              })
                .then(({ data }: any) => {
                  let errorFindingRemediationResults: any[] = [];
                  data?.initiateRemediations?.forEach(
                    (findingRemediationResult: any) => {
                      if (findingRemediationResult.status !== "APPROVED") {
                        errorFindingRemediationResults.push(
                          findingRemediationResult
                        );
                      }
                    }
                  );
                  if (errorFindingRemediationResults.length > 0) {
                    let errors: string[] = [];
                    errorFindingRemediationResults.forEach((result) => {
                      if (!errors.includes(result.error))
                        errors.push(result.error);
                    });
                    if (errors.includes("ROLE_NOT_FOUND")) {
                      setError({
                        status: true,
                        msg: "ROLE_NOT_FOUND",
                      });
                    } else {
                      setError({
                        status: true,
                        msg: `${
                          errorFindingRemediationResults.length
                        } finding/s failed to remediate. Please try again. Error: ${errors.join(
                          ","
                        )}`,
                      });
                    }
                  } else {
                    handleConfirm(errorFindingRemediationResults.length === 0);
                  }
                })
                .catch((e) => {
                  noticeError(e, "RemediateFailedFindingsDialog");
                  handleConfirm(false);
                });
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
        {error.status && (
          <>
            {error.msg === "ROLE_NOT_FOUND" ? (
              <>
                <Alert severity="error">
                  <Typography sx={{ fontSize: "12px" }}>
                    We could not find a suitable role for this control. To
                    update the role click below.
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 1 : Click Open CloudFormation to open the AWS console
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 2 : Find the <b>six-pillars</b> stack and click update
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 3 : Click the Role stack URL
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 4 : Choose the Replace existing template option and Add
                    the URL and click NEXT
                  </Typography>
                  <Typography sx={{ fontSize: "12px" }}>
                    Step 4 : Choose the Replace existing template option and Add
                    the URL and click NEXT and Update Role Parameters with YES
                  </Typography>
                  <SixPillarsRoleButton
                    launch={false}
                    update={true}
                    open={true}
                    uuid={externalId}
                    accountRegion={defaultregion}
                    template={
                      accounttype === "AUTOMATED"
                        ? "roles/SixPillarRoleStack.template.json"
                        : "roles/SixPillarReadOnlyRoleStack.template.json"
                    }
                  ></SixPillarsRoleButton>
                </Alert>
              </>
            ) : (
              <Alert severity="error">{error.msg}</Alert>
            )}
          </>
        )}
        {loading && <LinearProgress color="secondary" />}
      </Dialog>
    </>
  );
};

export default RemediateFailedFindingsDialog;
