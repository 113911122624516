import { OnResultDocument, useUpdateIacAnswerMutation } from "../generated";
import { useApolloClient } from "@apollo/client";
import useNewrelic from "./useNewrelic";

const useUpdateIacAnswer = () => {
	const [updateIacAnswerMutation] = useUpdateIacAnswerMutation();
	const client = useApolloClient();
	const { noticeError } = useNewrelic();

	const updateIacWorkload = async (workloadid: string, aws_wafr_account: string, tscan_summary_ids: number[]) => {
		const workloadIacupdate = await updateIacAnswerMutation({
			variables: {
				workloadid: workloadid, // value for 'workloadid'
				lens: "wellarchitected", // value for 'lens'
				awsWafrAccount: aws_wafr_account, // value for 'awsWafrAccount'
				summaryId: tscan_summary_ids, // value for 'summaryId'
			},
			context: {
				apiName: "well_architected",
			},
			notifyOnNetworkStatusChange: true,
		}).then(async ({data}:any) =>{
			let substatus = false;
			const subscriptionPromise = await new Promise(async (resolve, reject) => {
				client
				?.subscribe({
					query: OnResultDocument,
					variables: {
							id: data?.updateIacAnswer?.id,
					},
				})
				.subscribe({
					next: () => {
						substatus = true;
						resolve(
							true
						);
					},
					error: () => {
						substatus = false;
						reject(
							new Error("Error in Update Workload!"),
						);  
					},
				});
			}).then(() =>{
				return substatus;
			}).catch(error => {
				noticeError(error.message, "useUpdateIacAnswer");
			});
			return subscriptionPromise;
		});
		return {workloadIacupdate};
	};

	return {updateIacWorkload};
};

export { useUpdateIacAnswer };