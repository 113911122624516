import { ChangeEvent, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  AutomateProgressStatusDocument,
  GetUrlDocument,
  OnResultDocument,
  useCreateAccountMutation,
} from "../../../generated";
import { useApolloClient } from "@apollo/client";
import { v5 as uuidv5 } from "uuid";
import { useFormik } from "formik";
import * as yup from "yup";
import useAccount from "../../../hooks/useAccount";
import useRegions from "../../../hooks/useRegions";
import { Alert, CircularProgress, LinearProgress } from "@mui/material";
import { DatasetSharp, SaveOutlined } from "@mui/icons-material";

interface CrossAccountRegionProps {
  accountType: string;
  deploymentType: string;
  setValue: any;
  setAccountCreated: any;
  setTabSwitch: any;
}

const style = {
  position: "absolute" as "absolute",
  transform: "translate(-50%, -50%)",
  width: "550px",
  height: "Hug (268px)",
  top: "50%",
  left: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  radius: "8px",
  padding: "32px",
  gap: "32px",
};

const accountNameRegExp = /^[a-zA-Z0-9_-\s+]{1,32}$/;
const accountIdRegExp = /[0-9]{12}/;

const validationSchema = yup.object({
  accountName: yup
    .string()
    .matches(accountNameRegExp, "Account Name is not valid")
    .required("Account Name is required"),
  accountId: yup
    .string()
    .matches(accountIdRegExp, "Account Id is not valid")
    .required("Account Id is required"),
});

enum ViewStep {
  CREATE_FORM,
  VALIDATE_MODAL,
  TEMPLATE_MODAL,
}

enum DataStatus {
  INITIAL,
  VALIDATING_ACCOUNT_ID,
  CREATING_ACCOUNT,
  ACCOUNT_CREATED,
  PREPARING_TEMPLATE,
  ERROR,
}

export default function CrossAccountRegion({
  accountType,
  deploymentType,
  setValue,
  setAccountCreated,
  setTabSwitch,
}: CrossAccountRegionProps) {
  const regions = useRegions();
  const [accountRegion, setAccountRegion] = useState("");
  const { refreshAccounts } = useAccount();
  const [status, setStatus] = useState({
    viewStep: ViewStep.CREATE_FORM,
    dataStatus: DataStatus.INITIAL,
  });
  const [templateUrl, setTemplateUrl] = useState("");
  const [validAccountId, setValidAccountId] = useState("");

  const client = useApolloClient();
  const handleClose = () => {
    setAccountRegion("");
    setStatus({
      viewStep: ViewStep.CREATE_FORM,
      dataStatus: DataStatus.INITIAL,
    });
    setTabSwitch(true);
    setValue(1);
  };

  const closeValidateModal = () => {
    setStatus({
      viewStep: ViewStep.CREATE_FORM,
      dataStatus: DataStatus.INITIAL,
    });
  };

  const formik = useFormik({
    initialValues: {
      accountName: "",
      accountId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setStatus({
        viewStep: ViewStep.VALIDATE_MODAL,
        dataStatus: DataStatus.VALIDATING_ACCOUNT_ID,
      });
    },
  });

  //Create Account
  const [createAccountMutation] = useCreateAccountMutation();

  //Get CFN Template for six pillars stack and open it in new tab window
  const [getRoleTemplateURL] = useLazyQuery(GetUrlDocument);

  const handleRegion = (event: SelectChangeEvent) => {
    setAccountRegion(event.target.value as string);
  };

  const handleValidateAccountId = (event: ChangeEvent<HTMLInputElement>) => {
    setValidAccountId(event.target.value);
  };

  // Call Automate Progress Status Query Once Upgrade Process Statemachine has been triggered
  const [automateProgressStatus] = useLazyQuery(AutomateProgressStatusDocument);

  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, "0");
  };

  const getUuid = (accountId: string) => {
    let dateTime = new Date();
    const timeStr = [
      padTo2Digits(dateTime.getHours()),
      padTo2Digits(dateTime.getMinutes()),
      dateTime.getSeconds().toString(),
    ].join("");
    return uuidv5(
      `${timeStr}-${accountId}`,
      process.env.REACT_APP_UUID_NAMESPACE || ""
    );
  };

  const launchCfnTemplate = (uuid: string, roleTemplateURLData: any) => {
    let launchurl = `https://console.aws.amazon.com/cloudformation/home?region=${accountRegion}#/stacks/create/review?stackName=six-pillars&templateURL=${encodeURIComponent(
      roleTemplateURLData?.data?.getTemplateS3URL
    )}&param_ExternalId=${uuid}&param_EnableSixPillarsDeployAccess=YES`;
    setTemplateUrl(launchurl);
  };

  const waitForAccountCreationResult = (
    startCreateAccountResponse: any,
    uuid: string,
    roleTemplateURLData: any
  ) => {
    client
      ?.subscribe({
        query: OnResultDocument,
        variables: {
          id: startCreateAccountResponse?.data?.createAccount?.id,
        },
      })
      .subscribe(({ data }: any) => {
        if (data?.onResult?.status === "COMPLETE") {
          launchCfnTemplate(uuid, roleTemplateURLData);
          setAccountCreated(true);
          refreshAccounts("");
          setStatus({
            viewStep: ViewStep.TEMPLATE_MODAL,
            dataStatus: DataStatus.ACCOUNT_CREATED,
          });
        } else {
          setStatus({
            viewStep: ViewStep.CREATE_FORM,
            dataStatus: DataStatus.ERROR,
          });
        }
      });
  };

  const getRoleTemplateURLPromise = () => {
    let templateType = "";
    if (accountType === "AUTOMATED") {
      templateType = "roles/SixPillarRoleStack.template.json";
    } else {
      templateType = "roles/SixPillarReadOnlyRoleStack.template.json";
    }

    return getRoleTemplateURL({
      variables: {
        template: templateType, // value for 'template'
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    });
  };

  const startCreateAccountPromise = (uuid: string, values: any) => {
    return createAccountMutation({
      variables: {
        arn: "arn:aws:iam::" + values.accountId + ":role/six-pillars-role", // value for 'arn'
        accessnickname: values.accountName, // value for 'accessnickname'
        accounttype: accountType, // value for 'accounttype'
        defaultregion: accountRegion, // value for 'defaultregion'
        externalid: uuid, // value for 'externalid'
      },
      context: {
        apiName: "user_deploy_process",
      },
    });
  };

  const deployRole = async (values: any) => {
    setStatus({
      viewStep: ViewStep.TEMPLATE_MODAL,
      dataStatus: DataStatus.CREATING_ACCOUNT,
    });
    let uuid = getUuid(values.accountId);
    const responses = await Promise.all([
      startCreateAccountPromise(uuid, values),
      getRoleTemplateURLPromise(),
    ]);
    waitForAccountCreationResult(responses[0], uuid, responses[1]);
  };

  const startAutomateProgress = () => {
    //Add a delay before calling Automate Progress API so that the Setup Process Statemachine can start
    //And an entry can be made into the database with the new statemachine execution arn
    setTimeout(
      () =>
        automateProgressStatus({
          variables: {
            accountid: formik.values.accountId,
          },
          context: {
            apiName: "user_deploy_process",
          },
          fetchPolicy: "no-cache",
        }).then((result) => {
          handleClose();
        }),
      30000
    );
  };

  return (
    <>
      <form>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={4}>
            <TextField
              required
              fullWidth
              id="accountName"
              placeholder="Name this deployment"
              label="Name this deployment"
              helperText="1-32 characters, lower & upper case, numeric, dashes and spaces."
              value={formik.values.accountName}
              error={
                formik.touched.accountName && Boolean(formik.errors.accountName)
              }
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item md={8}></Grid>
          <Grid item md={4}>
            <TextField
              required
              fullWidth
              id="accountId"
              placeholder="AWS Account ID"
              label="AWS Account ID"
              value={formik.values.accountId}
              error={
                formik.touched.accountId && Boolean(formik.errors.accountId)
              }
              helperText={formik.touched.accountId && formik.errors.accountId}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item md={8}></Grid>
          <Grid item md={4}>
            <FormControl fullWidth>
              <InputLabel id="dep_arn">Region</InputLabel>
              <Select
                required
                id="dep_region"
                value={accountRegion}
                label="Region"
                onChange={handleRegion}
              >
                {Array.from(regions.keys()).map((region: any) => {
                  return (
                    <MenuItem key={region} value={region}>
                      {regions.get(region)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={8}></Grid>
          <Grid item md={12}></Grid>
          <Grid item xs md={4} justifyContent="flex-end">
            <Stack direction="row">
              <LoadingButton
                id="automate-setup-btn"
                variant="contained"
                onClick={() => {
                  formik.handleSubmit();
                }}
                loadingPosition="end"
                disabled={
                  status.dataStatus !== DataStatus.INITIAL &&
                  status.dataStatus !== DataStatus.ERROR
                }
                sx={{
                  width: 157,
                  height: 40,
                  bgcolor: "secondary.main",
                  "&:hover": {
                    bgcolor: "secondary.main",
                    color: "secondary.contrastText",
                  },
                }}
              >
                Deploy
              </LoadingButton>
              <Modal
                open={
                  status.viewStep === ViewStep.TEMPLATE_MODAL ||
                  status.viewStep === ViewStep.VALIDATE_MODAL
                }
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  {status.viewStep === ViewStep.TEMPLATE_MODAL ? (
                    <>
                      <Grid
                        container
                        sx={{
                          width: "Fill (486px)",
                          height: "Hug (116px)",
                          top: "32px",
                          left: "32px",
                        }}
                      >
                        <Grid item md={10}>
                          <Typography id="modal-modal-title" variant="h6">
                            Create AWS CloudFormation URL
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={2}
                          sx={{
                            width: "Hug (16px)",
                            height: "Hug (16px)",
                            left: "470px",
                          }}
                        >
                          <Button
                            disabled={templateUrl === ""}
                            onClick={() => handleClose()}
                          >
                            <CloseIcon />
                          </Button>
                        </Grid>
                        <Grid item md={12} sx={{ pt: 2 }}>
                          <Alert variant="outlined" severity="info">
                            Note: Please wait! We are creating an AWS
                            CloudFormation quick launch URL. Please keep the
                            6pillars SaaS & AWS account browser windows open for
                            the next 20 mins to ensure successful deployment.
                          </Alert>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          width: "Fill (486px)",
                          height: "Hug (56px)",
                          top: "180px",
                          left: "32px",
                          gap: "16px",
                        }}
                      >
                        <Grid
                          item
                          md={12}
                          sx={{
                            gap: "16px",
                          }}
                        ></Grid>
                        <Grid
                          item
                          md={12}
                          sx={{
                            width: "Hug (111px)",
                            height: "Hug (40px)",
                            top: "16px",
                            left: "375px",
                          }}
                        >
                          <FormControl fullWidth>
                            <LoadingButton
                              href={templateUrl}
                              id="automate-template-launch-rollback-btn"
                              type="submit"
                              target={"_blank"}
                              rel={"noopener"}
                              variant="contained"
                              disabled={templateUrl === ""}
                              loading={templateUrl === ""}
                              onClick={() => {
                                startAutomateProgress();
                              }}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                bgcolor: "secondary.main",
                                "&:hover": {
                                  bgcolor: "secondary.main",
                                  color: "secondary.contrastText",
                                },
                              }}
                              endIcon={<OpenInNewIcon />}
                              loadingPosition="end"
                            >
                              Open Template in AWS CloudFormation
                            </LoadingButton>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        container
                        sx={{
                          width: "Fill (486px)",
                          height: "Hug (116px)",
                          top: "32px",
                          left: "32px",
                        }}
                      >
                        <Grid item md={10}>
                          <Typography id="modal-modal-title" variant="h6">
                            Please Validate AWS Account ID to Proceed
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={2}
                          sx={{
                            width: "Hug (16px)",
                            height: "Hug (16px)",
                            left: "470px",
                          }}
                        >
                          <Button
                            disabled={
                              status.dataStatus === DataStatus.CREATING_ACCOUNT
                            }
                            onClick={() => closeValidateModal()}
                          >
                            <CloseIcon />
                          </Button>
                        </Grid>
                        <Grid item md={12}>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2, mb: 2 }}
                          >
                            Please check you have logged into the AWS Account
                            ending with{" "}
                            <b>
                              {formik.values.accountId
                                .substring(9, 12)
                                .padStart(formik.values.accountId.length, "*")}
                            </b>
                            .
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2, mb: 2 }}
                          >
                            Copy-paste the account id from your AWS console into
                            the empty field to validate and complete the
                            deployment of the stack.
                          </Typography>
                        </Grid>
                        <Grid item md={12}></Grid>
                        <Grid item md={5}>
                          <TextField
                            required
                            fullWidth
                            id="validateAccountId"
                            placeholder="AWS Account ID"
                            label="AWS Account ID"
                            value={validAccountId}
                            helperText={
                              validAccountId === formik.values.accountId
                                ? "AccountId's match!"
                                : "AccountId's must match"
                            }
                            color={
                              validAccountId === formik.values.accountId
                                ? "success"
                                : "error"
                            }
                            onChange={handleValidateAccountId}
                          />
                        </Grid>
                        <Grid item md={7}></Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          width: "Fill (486px)",
                          height: "Hug (56px)",
                          top: "180px",
                          left: "32px",
                          gap: "16px",
                        }}
                      >
                        <Grid
                          item
                          md={12}
                          sx={{
                            gap: "16px",
                          }}
                        ></Grid>
                        <Grid
                          item
                          md={12}
                          sx={{
                            width: "Hug (111px)",
                            height: "Hug (40px)",
                            top: "16px",
                            left: "375px",
                          }}
                        >
                          <Button
                            disabled={
                              validAccountId !== formik.values.accountId
                            }
                            id="connect-acount-modal-close"
                            variant="contained"
                            sx={{
                              width: 157,
                              height: 40,
                              bgcolor: "secondary.main",
                              "&:hover": {
                                bgcolor: "secondary.main",
                                color: "secondary.contrastText",
                              },
                            }}
                            onClick={() => {
                              deployRole(formik.values);
                            }}
                          >
                            Validate
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Box>
              </Modal>
            </Stack>
          </Grid>
          <Grid item md={12}></Grid>
          <Grid item md={12}>
            {status.dataStatus === DataStatus.ERROR ? (
              <Alert severity="error">Account Creation Failed</Alert>
            ) : null}
            {status.dataStatus === DataStatus.ACCOUNT_CREATED ? (
              <Alert severity="success">Account successfully created</Alert>
            ) : null}
          </Grid>

          <Grid item md={12}>
            <Typography variant="body2" color="secondary.main" paragraph>
              Note: Please note that due to deployment of additional AWS native
              security services as part of 6pillars' AUTOMATE+, you may notice
              an incremental increase in your AWS consumption costs.
            </Typography>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
