import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface ProgressProps {
  progress: number;
  message?: string;
}

const Progress = ({ progress, message }: ProgressProps) => {
  return (
    <Box sx={{ pl: 5, p: 1 }}>
      <LinearProgress variant="determinate" value={progress} />
      <Typography variant="body2" color="textSecondary">
        {message}
      </Typography>
    </Box>
  );
};

export default Progress;
