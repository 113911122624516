import React, { FC } from "react";
import { LinearProgress, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Control from "../../models/Control";
import { Row } from "@tanstack/react-table";
import { useUpdateControlAlertStatusMutation } from "../../generated";
import useAccount from "../../hooks/useAccount";
import useNewrelic from "../../hooks/useNewrelic";

const AlertDialog: FC<any> = ({
  open,
  handleClose,
  handleConfirm,
  row,
}: {
  open: boolean;
  handleClose: any;
  handleConfirm: any;
  row: Row<Control>;
}) => {
  const { arn } = useAccount();
  const { noticeError } = useNewrelic();
  let action = row.original.alertstatus === "ENABLED" ? "Disable" : "Enable";

  const [updateControlAlertStatus, { loading }] =
    useUpdateControlAlertStatusMutation();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{action} Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Control ID: ${
              row.original.controlname
            } You are about to ${action.toLocaleLowerCase()} alert for this control`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            disabled={loading}
            color="secondary"
            onClick={() => {
              let val = action.toUpperCase();
              updateControlAlertStatus({
                variables: {
                  arn: arn,
                  controlname: row.original.controlname,
                  action: val,
                },
              })
                .then(({ data }: any) => {
                  handleConfirm(data?.updateControlAlertStatus?.status);
                })
                .catch((error) => {
                  noticeError(error, "AlertDialog");
                  handleConfirm(false);
                });
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
        {loading && <LinearProgress color="secondary" />}
      </Dialog>
    </>
  );
};

export default AlertDialog;
