import { Box, CircularProgress, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ConnectedAccount from "../../../models/ConnectedAccount";
import { useListSecurityControlsQuery } from "../../../hooks/useListSecurityControlsQuery";
import { useCallback, useEffect, useState } from "react";
import Control from "../../../models/Control";
import { Row } from "@tanstack/react-table";
import Loader from "../../common/Loader";
import LoadingButton from "@mui/lab/LoadingButton";
import TechnicalControl from "./TechnicalControl";

interface TechnicalControlsListProps {
  frameworkSelection: string;
  technicalReviewArn: string;
  connectedAccounts: Array<ConnectedAccount>;
  setTechnicalControlsData: any;
  setUnlockSteps: React.Dispatch<
    React.SetStateAction<{
      step2: boolean;
      step3: boolean;
      step4: boolean;
      step5: boolean;
    }>
  >;
  changeTab: (index: number) => void;
}

type RowQuestion = {
  controlname: string;
  remediationsteps: string;
  status: string;
  updateStatus: string;
  reducedHri: number;
};

/**
 * Return row data
 * @param controlname control name data 
 * @param status control status
 * @param updateStatus used for remediation
 * @param original original row information
 * @returns 
 */
function createData(
  controlname: string,
  status: string,
  updateStatus: string,
  original: Row<Control>[]
) {
  return {
    controlname,
    status,
    updateStatus,
    original,
  };
}

/**
 * File Receives the framework that was selected
 * Displays all controls associated with the framework
 * including disruptive and non disruptive
 * @param technicalReviewArn technical reveiew arn that was selected when creating the framework
 * @returns Entire List of Technical Controls
 */
export default function TechnicalControlsList({
  frameworkSelection,
  technicalReviewArn,
  connectedAccounts,
  setTechnicalControlsData,
  setUnlockSteps,
  changeTab,
}: TechnicalControlsListProps){
  const { controls, loading } = useListSecurityControlsQuery({
    arn: technicalReviewArn,
    connectedAccounts,
  });

  const [rows, setRows] = useState<Array<RowQuestion>>([]);

  //Handle that unlocks step 2
  const handleUnlockSteps = useCallback(() => {
    setUnlockSteps((prevState) => ({
      ...prevState,
      step2: false, // Lock step 2
      step3: true, // Unlock Step 3
    }));
  }, [setUnlockSteps]);

  //Move to Step 3
  const handleSubmit = useCallback(() => {
    handleUnlockSteps();
    changeTab(2);
  }, [handleUnlockSteps, changeTab]);  

  useEffect(() => {
    const data: any = controls.filter((control: any) => {
      if (
        Array.isArray(control?.customStandard) &&
        control?.customStandard.length > 0 &&
        control?.customStandard.some((standard: string) => {
          return standard.includes(frameworkSelection);
        })
      ) {
        return true;
      }
      return false;
    });
    if (!loading && data) {
      setRows(
        data?.map((q: any) => {
          return createData(
            q.controlname,
            "",
            "INITIAL",
            q
          );
        })
      );
      setTechnicalControlsData(data);
    } else {
      setRows([]);
      setTechnicalControlsData([]);
    }
  }, [loading, controls, frameworkSelection]);
  
  return (
    <Container maxWidth="lg" component={Paper}>
      <Grid
        container
        sx={{ pb: 2, pt: 2, pl: 2 }}
        spacing={2}
        alignItems="center"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Control ID</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="center">Compliance</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow
                  key={1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={12}>
                    <Loader/>
                  </TableCell>
                </TableRow>
              )}
              {rows.map((row, index) => (
                <TechnicalControl
                  key={row.controlname}
                  index={index}  
                  technicalReviewArn={technicalReviewArn}
                  row={row}
                  connectedAccounts={connectedAccounts}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item md={10} />
        <Grid item md={2}>
          <Box>
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              onClick={handleSubmit}
              sx={{
                width: 157,
                height: 40,
                bgcolor: "secondary.main",
                "&:hover": {
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                },
              }}
              loadingIndicator={
                <CircularProgress color={"secondary"} size={22} />
              }
            >
              Next
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  ) 
}